import React from "react"
import { Icon } from "../../shared/Icon/Icon"
import cs from "classnames"
import "./DatePickerInput.scss"

interface DatePickerInputProps {
  value?: string
  onClick?: () => void
  placeholder?: string
  className?: string
}

export const DatePickerInput = (props: DatePickerInputProps) => {
  const { value, onClick, placeholder, className } = props
  return (
    <div className={cs("DatePickerInput", className)}>
      <input
        type="text"
        className="DatePickerInput__input"
        placeholder={placeholder}
        value={value}
        onClick={onClick}
      />
      <Icon
        className="DatePickerInput__icon"
        onClick={onClick}
        icon="calendar"
      />
    </div>
  )
}
