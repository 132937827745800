import { apiRequest } from "./Api"

export interface LanguagesRows {
  id: number
  language: string
  locale: string
}

export interface LanguagesResponse {
  total_data: number
  rows: LanguagesRows[]
}

export interface AddEditLanguageInput {
  locale: string
  language: string
}
export interface AddEditLanguageResponse {
  language: LanguagesRows
}

export interface LanguageByIdResponse {
  id: number
  language: string
  locale: string
}
export const getLanguages = async () =>
  apiRequest<undefined, LanguagesResponse>("get", "api/languages")

export const createLanguage = async (data: AddEditLanguageInput) =>
  apiRequest<AddEditLanguageInput, AddEditLanguageResponse>(
    "post",
    "api/languages",
    data
  )
export const editLanguage = async (data: AddEditLanguageInput) =>
  apiRequest<AddEditLanguageInput, AddEditLanguageResponse>(
    "put",
    `api/languages`,
    data
  )

// export const deleteLanguage = async (locale: string) =>
//   apiRequest<string, undefined>("delete", `api/languages/${locale}`)

export const getLanguageById = async (langId?: number) =>
  apiRequest<number, LanguageByIdResponse>("get", `api/languages/${langId}`)
