import React from "react"

import "./LineTooltip.scss"

export interface Payload {
  color?: string
  value: number
  name?: string
  dataKey: string
}

interface Props {
  active?: boolean
  payload?: Payload[]
  label?: string
}
export const LineTooltip = ({ active, payload }: Props) => {
  if (active && payload && payload.length) {
    return (
      <ul className="LineTooltip">
        {payload.map((entry) => {
          if (!entry.value) return null
          return (
            <li
              key={`tooltip-item-${entry.dataKey}`}
              className="LineTooltip__item"
            >
              <div
                className="LineTooltip__indicator"
                style={{ backgroundColor: `${entry.color}` }}
              ></div>
              {entry.name}: {entry.value}
            </li>
          )
        })}
      </ul>
    )
  }
  return null
}
