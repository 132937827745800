import React from "react"
import { useReportsFormik } from "../../../lib/hooks/formik/useReportsFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { DatePicker } from "../../DatePicker/DatePicker"
import { formatDate } from "../../../lib/helpers/formatDate"
import { FormButtons } from "../../shared/FormButtons/FormButtons"
import { Input } from "../../shared/Input/Input"
import { FileInput } from "../../shared/FileInput/FileInput"
import { FilePondFile } from "filepond"

import * as API from "../../../api/Api"

import "./ReportsForm.scss"
import { useTranslation } from "react-i18next"

interface Props {
  locale?: string

  toggle: () => void
  onAddReport: (report: API.CreateReportResponse) => void
}

export const ReportsForm = ({ locale, toggle, onAddReport }: Props) => {
  const errorHandler = useErrorHandler()

  const formik = useReportsFormik({
    onSubmit: async (values, formikHelpers) => {
      const date = formatDate(values.date)
      const file = values.file
      try {
        let formData = new FormData()
        formData.append("name", values.name)
        formData.append("date", date || "")
        formData.append("locale", locale || "")
        formData.append("file", file[0] as any)
        const res = await API.createReport(formData)
        onAddReport(res)
        formikHelpers.resetForm()
        toggle && toggle()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })
  const { t } = useTranslation()
  const handleUpdateFiles = (fileItems: FilePondFile[]) => {
    const files = fileItems.map((fileItem) => {
      if (!fileItem.file.lastModified)
        return new File([fileItem.file], fileItem.file.name)
      return fileItem.file
    })
    formik.setFieldValue("file", files, true)
  }

  return (
    <div className="ReportsForm">
      <form className="ReportsForm__form" onSubmit={formik.handleSubmit}>
        <Input
          className="ReportsForm__input"
          type="text"
          name="name"
          id="name"
          placeholder="Name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.name}
          error={formik.errors.name}
        />

        <DatePicker
          className="ReportsForm__date"
          placeholder={t("Select date")}
          onChange={(date: [Date, Date]) => {
            formik.setFieldValue("date", date)
          }}
          startDate={formik.values.date}
          endDate={formik.values.date}
          selectsRange={false}
          error={formik.errors.date}
        />
        <FileInput
          name="files"
          allowDrop={true}
          allowMultiple={false}
          files={formik.values.file}
          onupdatefiles={(fileItems) => handleUpdateFiles(fileItems)}
        />

        <FormButtons
          toggle={toggle}
          disabled={!formik.isValid || formik.isSubmitting}
          isSubmitting={formik.isSubmitting}
        />

        <ErrorMessage errorData={errorHandler} onClose={errorHandler.reset} />
      </form>
    </div>
  )
}
