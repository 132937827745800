import React, { useCallback, useEffect, useState } from "react"

import { useLocation } from "react-router-dom"

import { ROOT_DOWNLOAD_URL } from "../../components/CasesTable/CasesTable"
import { HandleErrorState } from "../../components/shared/HandleErrorState/HandleErrorState"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { useCasesFormik } from "../../lib/hooks/formik/useCasesFormik"
import { getDownloadUrl } from "../../lib/helpers/getDownloadUrl"
import { useTranslation } from "react-i18next"
import { ReadMoreModal } from "../../components/CasesTable/ReadMoreModal/ReadMoreModal"
import { useCasesRows } from "../../components/CasesTable/useCasesRows/useCasesRows"
import { TableColumn } from "../../components/shared/Table/Table"
import { formatDate } from "../../lib/helpers/formatDate"
import { CasesTable } from "../../components/CasesTable/CasesTable"
import { Filters } from "../../components/Filters/Filters"
import { Button } from "../../components/shared/Button/Button"
import { getIds } from "../../lib/helpers/getIds"

import { useRouter } from "../../lib/hooks/useRouter"

import * as API from "../../api/Api"

import "./Cases.scss"
import { useQueryParameters } from "../../lib/hooks/useQueryParameters"

export interface FiltersState {
  title?: string
  affectedParties?: { value: string; label: string }[] | null
  attackers?: { value: string; label: string }[] | null
  country_id?: { value: string; label: string } | null

  meansOfAttacks?: { value: string; label: string }[] | null
  categories?: { value: string; label: string }[] | null
  dates?: [Date | null, Date | null]
}

export const Cases = () => {
  const { t, i18n } = useTranslation()
  const errorHandler = useErrorHandler()
  const { getUrlWithQueryParams } = useQueryParameters()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const paramValue = searchParams.get("case")

  const router = useRouter()

  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [caseId, setCaseId] = useState<number>(0)
  const [cases, setCases] = useState<API.CasesResponse>()

  //THE LANGUAGE PREFIX IS ADDED ON THE DOWNLOAD BUTTON HREF PROPERTY, BECAUSE IT NEEDS TO GET THE CURRENT LANGUAGE WHEN IT IS CLICKED
  //Example: locale=en
  const [downloadUrl, setDownloadUrl] = useState<string>(
    () => ROOT_DOWNLOAD_URL + `?locale=`
  )

  const sortUrl = "&sortByAsc=start_date"

  const toggleModal = () => {
    router.history.push(`/${i18n.language}/data`)
  }

  const handleRowClick = (id: number) => {
    const url = getUrlWithQueryParams("case", id)
    router.history.push(url)
  }

  const formik = useCasesFormik({
    onSubmit: async (values) => {
      setPageNumber(1)
      const url = getDownloadUrl(pageNumber, filters, i18n.language)
      setDownloadUrl(url)
      try {
        setIsLoading(true)
        const res = await API.getAllCases(pageNumber, filters, i18n.language)
        setCases(res)
      } catch (e) {
        errorHandler.handleError(e)
      } finally {
        setIsLoading(false)
      }
    }
  })
  useEffect(() => {
    setCaseId(paramValue ? +paramValue : 0)
  }, [location, paramValue])

  const filters = {
    affectedPartiesId: getIds(formik.values.affectedParties),
    attackersId: getIds(formik.values.attackers),
    meansOfAttacksId: getIds(formik.values.meansOfAttacks),
    categoriesId: getIds(formik.values.categories),
    country_id: getIds(formik.values.country_id),
    startDate: formatDate(formik.values.dates?.[0]),
    endDate: formatDate(formik.values.dates?.[1]),
    title: formik.values.title
  }

  const getAllCases = useCallback(async () => {
    try {
      const res = await API.getAllCases(pageNumber, filters, i18n.language)
      setCases(res)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, i18n.language])

  useEffect(() => {
    getAllCases()
  }, [getAllCases])

  const handlePageChange = (currentPage: number) => {
    setPageNumber(currentPage)
  }

  //table properties
  const columns: TableColumn[] = [
    { key: "title", header: t("Title"), width: 350 },
    { key: "category", header: t("Category") },
    // { key: "country", header: t("Country"), width: 239 },
    { key: "date", header: t("Date"), width: 125 },
    { key: "action", header: "", width: 200 }
  ]
  const rows = useCasesRows(cases?.rows, handleRowClick)

  return (
    <>
      <Filters formik={formik} />

      <HandleErrorState error={errorHandler.error}>
        <Button
          className="Cases__download"
          icon="downloadData"
          iconclassname="Cases__download_icon"
          //THE LANGUAGE PREFIX SETTED ON THE HREF PROPERTY, BECAUSE IT NEEDS TO GET THE CURRENT SELECTED LANGUAGE WHEN IT IS CLICKED
          href={downloadUrl + i18n.language + sortUrl}
          download
        >
        {t("Download data")}
        </Button>

        <CasesTable
          onPageChange={handlePageChange}
          totalData={cases?.total_data}
          error={errorHandler.error}
          toggleModal={toggleModal}
          currentPage={pageNumber}
          showModal={caseId !== undefined}
          loading={isLoading}
          columns={columns}
          caseId={caseId}
          rows={rows}
        />

        {caseId !== 0 && (
          <ReadMoreModal
            isOpen={caseId !== 0}
            toggle={toggleModal}
            caseId={caseId}
          />
        )}
      </HandleErrorState>
    </>
  )
}
