import React from "react"
import { CasesDashboardForm } from "../CasesDashboardForm/CasesDashboardForm"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { useCasesDashboardFormik } from "../../../lib/hooks/formik/useCasesDashboardFormik"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { caseFormData } from "../../../lib/helpers/caseFormData"
import { formatInitialValues } from "../../../lib/helpers/formatInitialValues"

import * as API from "../../../api/Api"

interface Props {
  locale: string
  caseId: number
  onEditCase: () => void
  toggle?: () => void
}

export const EditCase = ({
  caseId,
  onEditCase,
  locale,
  toggle,
  ...rest
}: Props) => {
  const { loading, data: caseById, error } = useApiCall(API.getCaseById, [
    caseId,
    locale
  ])
  const errorHandler = useErrorHandler()

  const initialValues = formatInitialValues(caseById)
  const formik = useCasesDashboardFormik({
    initialValues: initialValues,
    onSubmit: async (values) => {
      let formData = new FormData()
      const updatedFormData = caseFormData(formData, values, locale)
      try {
        await API.updateCase(updatedFormData, caseId)
        onEditCase()
        toggle && toggle()
      } catch (e) {
        errorHandler.handleError(e.message)
      }
    }
  })
  return (
    <CasesDashboardForm
      labelLoading={loading}
      error={error}
      formik={formik}
      errorHandler={errorHandler}
      toggle={toggle}
      locale={locale}
      caseFiles={caseById?.case.case_files}
      {...rest}
    />
  )
}
