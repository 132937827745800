import { apiRequest } from "./Api"

export interface AttackersTranslations {
  name: string
  locale: string
  attacker_id: number
}
export interface AttackersRows {
  id: number
  translations: AttackersTranslations[]
}
export interface AttackerResponse {
  total_data: number
  rows: AttackersRows[]
}
export interface Attackers {
  cases_count: number
  id: number
  translations: AttackersTranslations[]
}
export interface AttackersWithCountResponse {
  attackers: Attackers[]
}

export const getAllAttackers = async (locale: string) =>
  apiRequest<undefined, AttackerResponse>(
    "get",
    "api/attackers?sortByAsc=translations.name&relations=translations",
    undefined,
    { locale }
  )

export const getAttackersWithCount = async (locale: string) =>
  apiRequest<undefined, AttackersWithCountResponse>(
    "get",
    "api/attackers/cases-count",
    undefined,
    { locale }
  )
