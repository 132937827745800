import React from "react"
import { useTranslation } from "react-i18next"
import { Icon } from "../Icon/Icon"

import "./NoDataFound.scss"

export const NoDataFound = () => {
  const { t } = useTranslation()
  return (
    <div className="NoDataFound">
      <Icon className="NoDataFound__icon" icon="information" />
      <span className="NoDataFound__text">{t("No data found")}!</span>
    </div>
  )
}
