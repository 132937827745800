import React from "react"
import { Payload } from "../../LineChart/LineTooltip/LineTooltip"
import { Data } from "../../BarChart/BarChart"
import { useTranslation } from "react-i18next"

import "./Legend.scss"

interface LegendProps {
  payload?: any[]
  onMouseEnter?: (entry: Payload, index: number) => void
  onMouseLeave?: (entry: Payload, index: number) => void
  onClick?: (e: Payload) => void
  data?: Data[]
  colors?: string[]
}

export const Legend = ({
  payload,
  onMouseLeave,
  onClick,
  onMouseEnter,
  data,
  colors,
  ...rest
}: LegendProps) => {
  const { t } = useTranslation()

  return (
    <ul className="Legend">
      <h1 className="Legend__title"> {t("Legend")} </h1>
      {payload &&
        payload.map((entry, index) => (
          <li
            key={`legenditem-${index}`}
            className="Legend__item"
            onMouseEnter={() => onMouseEnter && onMouseEnter(entry, index)}
            onMouseLeave={() => onMouseLeave && onMouseLeave(entry, index)}
            onClick={() => onClick && onClick(entry)}
          >
            <div
              className="Legend__indicator"
              style={{
                backgroundColor: `${
                  entry.color ? entry.color : colors?.[index]
                }`
              }}
            ></div>

            <p className="Legend__text">
              {entry.name ? entry.name : entry.value}
            </p>
          </li>
        ))}
    </ul>
  )
}
