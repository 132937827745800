import React from "react"
import { Information } from "../../components/header/Information/Information"
import { NavigationMenu } from "../../components/header/NavigationMenu/NavigationMenu"

import "./Header.scss"

export const Header = () => {
  return (
    <div className="Header">
      <Information />
      <NavigationMenu />
    </div>
  )
}
