import React from "react"
import { Legend } from "../components/Legend/Legend"
import { LineTooltip } from "./LineTooltip/LineTooltip"
import { useLineOpacity } from "../../../../lib/hooks/useLineOpacity"
import { ActiveDot } from "./ActiveDot/ActiveDot"
import { useQueryParameters } from "../../../../lib/hooks/useQueryParameters"
import { useRouter } from "../../../../lib/hooks/useRouter"
import { HandleErrorState } from "../../HandleErrorState/HandleErrorState"
import { ChartPlaceholder } from "../components/ChartPlaceholder/ChartPlaceholder"
import {
  LineChart as ReLineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ReTooltip,
  ResponsiveContainer,
  Legend as ReLegend,
  Line,
  Brush
} from "recharts"

import "./LineChart.scss"
import { useTranslation } from "react-i18next"

interface LineProps {
  data: any
  parents?: string[]
  id: string
  loading: boolean
  error: string
  max?: number
}

const colors = [
  "#A2A540",
  "#C3AC55",
  "#DFB46F",
  "#F5BE8C",
  "#CA896A",
  "#A34C70"
]
export const LineChart = ({
  id,
  data,
  parents,
  error,
  loading,
  max
}: LineProps) => {
  const { opacity, handleMouseEnter, handleMouseLeave } = useLineOpacity(
    parents
  )
  const {
    i18n: { language }
  } = useTranslation()
  const { getUrlWithQueryParams } = useQueryParameters()
  const router = useRouter()

  const handleClick = (data: any) => {
    if (!data) return
    const url = getUrlWithQueryParams(id, data)
    const urlArray = url.split("/")
    urlArray.splice(0, 2)
    router.history.push(`/${language}/data${urlArray.join("/")}`)
  }
  const handleLegendClick = (data: any) => {
    if (!data.value) return
    const url = getUrlWithQueryParams(id, data.value)
    const urlArray = url.split("/")
    urlArray.splice(0, 2)
    router.history.push(`/${language}/data${urlArray.join("/")}`)
  }

  const sortedData = data.sort(function (a: any, b: any) {
    return new Date(a.name).valueOf() - new Date(b.name).valueOf()
  })

  if (loading) return <ChartPlaceholder />
  return (
    <HandleErrorState error={error}>
      <ResponsiveContainer className="LineChart">
        <ReLineChart
          data={sortedData}
          margin={{ top: 5, right: 0, bottom: 100, left: 0 }}
        >
          {parents?.map((item: any, index: number) => {
            return (
              <Line
                key={`Line-${index}`}
                dataKey={item}
                strokeOpacity={opacity[item]}
                stroke={colors[index % colors.length]}
                activeDot={<ActiveDot onDotClick={handleClick} />}
                type="linear"
                strokeWidth={2}
                dot={false}
              />
            )
          })}

          <ReLegend
            content={
              <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleLegendClick}
              />
            }
            layout="vertical"
            verticalAlign="top"
            align="right"
          />
          <CartesianGrid stroke="#989696" />
          <XAxis dataKey="name" stroke="white" tickLine={false} tickSize={5} />
          <YAxis
            stroke="white"
            tickLine={false}
            height={20}
            domain={["dataMin", max || "dataMax"]}
          />
          <ReTooltip content={<LineTooltip />} />

          <Brush height={60} y={310} fill="#252020">
            <ReLineChart data={data}>
              {parents?.map((item: any, index: number) => (
                <Line
                  key={`Brush-${index}`}
                  dataKey={item}
                  strokeOpacity={opacity[item]}
                  stroke={colors[index % colors.length]}
                  type="linear"
                  strokeWidth={2}
                  dot={false}
                />
              ))}
              <XAxis hide tick={false} />
            </ReLineChart>
          </Brush>
        </ReLineChart>
      </ResponsiveContainer>
    </HandleErrorState>
  )
}
