import { useCallback, useState } from "react"

export const useLineOpacity = (data: any) => {
  const [opacity, setOpacity] = useState<any>(1)

  // useEffect(() => {
  //   if (data && data.length > 0) {
  //     setOpacity(
  // Object.assign({}, ...data?.map((item: any, index: any) => ({ [item]: 1 })))

  //     )
  //   }
  // }, [data])

  const handleMouseEnter = useCallback(
    (e: any) => {
      const { dataKey } = e
      setOpacity((prevOpacity: any) => {
        // Object.keys(prevOpacity).forEach((v) => (prevOpacity[v] = 0.5))
        return {
          ...prevOpacity,
          [dataKey]: 0.5
        }
      })
    },
    [setOpacity]
  )

  const handleMouseLeave = useCallback(
    (e: any) => {
      const { dataKey } = e
      setOpacity((prevOpacity: any) => {
        // Object.keys(prevOpacity).forEach((v) => (prevOpacity[v] = 1))
        return {
          ...prevOpacity,
          [dataKey]: 1
        }
      })
    },
    [setOpacity]
  )
  return { opacity, handleMouseEnter, handleMouseLeave }
}
