import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import * as API from "../../api/Api"
import { ReportsModal } from "../../components/Reports/ReportsModal/ReportsModal"
import { ReportsTable } from "../../components/ReportsTable/ReportsTable"
import { useReportsRows } from "../../components/ReportsTable/utils/useReportsRows"
import { AddNewButton } from "../../components/shared/AddNewButton/AddNewButton"
import { LocaleSelector } from "../../components/shared/LocaleSelector/LocaleSelector"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"

import "./ReportsDashboard.scss"

export const ReportsDashboard = () => {
  const { t, i18n } = useTranslation()
  const [pageNumber, setPageNumber] = useState(1)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [reportId, setReportId] = useState<number>()
  const [reports, setReports] = useState<API.ReportsResponse>()
  const [locale, setLocale] = useState<string>(i18n.language)
  const [deleting, setDeleting] = useState(false)

  const errorHandler = useErrorHandler()

  useEffect(() => {
    setLocale(i18n.language)
  }, [i18n.language])

  const getAllReports = useCallback(async () => {
    try {
      const res = await API.getReports(pageNumber, locale)
      setReports(res)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, locale])

  useEffect(() => {
    getAllReports()
  }, [getAllReports])

  const handleDeleteClick = async (reportId: number) => {
    try {
      setReportId(reportId)
      setDeleting(true)
      await API.deleteReport(reportId, locale)
      setReports((prevState) => {
        if (!prevState) return
        return {
          total_data: prevState.total_data,
          rows: prevState.rows.filter((row) => row.id !== reportId)
        }
      })
      setShowModal(false)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setDeleting(false)
    }
  }
  //table properties
  const columns = [
    { key: "fileName", header: t("file name") },
    { key: "date", header: t("Date"), width: 125 },
    { key: "preview", header: "", width: 125 },
    { key: "download", header: "", width: 125 },
    { key: "delete", header: "", width: 125 }
  ]
  const rows = useReportsRows(
    true,
    handleDeleteClick,
    reports?.rows,
    reportId,
    deleting
  )

  const handlePageChange = (currentPage: number) => {
    setPageNumber(currentPage)
  }
  const handleLocaleChange = (locale: string) => {
    setLocale(locale)
  }
  const handleAddReport = ({ report }: API.CreateReportResponse) => {
    setReports(
      (prevState) =>
        prevState && {
          total_data: prevState?.total_data,
          rows: [...prevState?.rows, report]
        }
    )
  }

  const toggleModal = () => setShowModal(!showModal)

  return (
    <div className="ReportsDashboard">
      <div className="ReportsDashboard__buttons">
        <div>
          <LocaleSelector
            language={locale}
            onLocaleChange={handleLocaleChange}
          />
        </div>
        <AddNewButton onClick={() => setShowModal(true)} />
      </div>
      <ReportsTable
        onPageChange={handlePageChange}
        loading={loading}
        reports={reports}
        columns={columns}
        currentPage={pageNumber}
        rows={rows}
        error={errorHandler.error}
      />
      <ReportsModal
        className="ReportsDashboard__modal"
        isOpen={showModal}
        toggle={toggleModal}
        locale={locale}
        onAddReport={handleAddReport}
        centered
      />
    </div>
  )
}
