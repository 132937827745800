import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface CasesFields {
  title?: string
  affectedParties?: { value: string; label: string }[] | null
  attackers?: { value: string; label: string }[] | null
  meansOfAttacks?: { value: string; label: string }[] | null
  categories?: { value: string; label: string }[] | null
  country_id?: { value: string; label: string }[] | null
  dates?: [Date | null, Date | null]
}

interface UseCasesFormOptions {
  initialValues?: CasesFields
  onSubmit: (
    values: CasesFields,
    formikHelpers: FormikHelpers<CasesFields>
  ) => Promise<any>
}

export const useCasesFormik = (options: UseCasesFormOptions) => {
  const CasesSchema = Yup.object().shape({
    title: Yup.string(),
    affectedParties: Yup.array(),
    attackers: Yup.array(),
    country_id: Yup.array(),
    meansOfAttacks: Yup.array(),
    categories: Yup.array(),
    dates: Yup.array()
  })

  return useFormik<CasesFields>({
    initialValues: {
      title: undefined,
      affectedParties: [],
      attackers: [],
      country_id: [],
      meansOfAttacks: [],
      categories: [],
      dates: [null, null],
      ...options.initialValues
    },
    enableReinitialize: true,
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CasesSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type CasesFormik = ReturnType<typeof useCasesFormik>
