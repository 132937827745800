import React from "react"
import { Spinner as RSpinner, SpinnerProps } from "reactstrap"
import cs from "classnames"
import "./Spinner.scss"

interface Props extends SpinnerProps {}
export const Spinner = (props: Props) => {
  const { className, ...rest } = props
  return (
    <div className="Spinner">
      <RSpinner
        className={cs("Spinner__spinner", props.className)}
        color="danger"
        {...rest}
      />
    </div>
  )
}
