import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface UsersFields {
  username: string
  email: string
  password?: string
  role_id: { value: string; label: string } | null
  current_password?: string
}

interface UseUsersFormOptions {
  initialValues?: UsersFields
  onSubmit: (
    values: UsersFields,
    formikHelpers: FormikHelpers<UsersFields>
  ) => Promise<any>
}

export const useUsersFormik = (options: UseUsersFormOptions) => {
  const UsersSchema = Yup.object().shape({
    username: Yup.string().required("Username is a required field!"),
    email: Yup.string().required("Email is a required field!"),
    password: Yup.string()
      .required("Password is a required field!")
      .min(6, "Your password must be minimum 6 characters long"),
    role_id: Yup.string().nullable().required("Role id is a required field!")
  })

  return useFormik<UsersFields>({
    initialValues: {
      username: "",
      email: "",
      password: "",
      current_password: "",
      role_id: null,
      ...options.initialValues
    },
    validateOnMount: true,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: UsersSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type UsersFormik = ReturnType<typeof useUsersFormik>
