import React from "react"
import { useCasesDashboardFormik } from "../../../lib/hooks/formik/useCasesDashboardFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { CasesDashboardForm } from "../CasesDashboardForm/CasesDashboardForm"
import { caseFormData } from "../../../lib/helpers/caseFormData"

import * as API from "../../../api/Api"

interface Props {
  locale: string
  onAddCase: () => void
  toggle?: () => void
}

export const AddCase = ({ locale, onAddCase, toggle, ...rest }: Props) => {
  const errorHandler = useErrorHandler()
  const formik = useCasesDashboardFormik({
    onSubmit: async (values) => {
      let formData = new FormData()

      const updatedFormData = caseFormData(formData, values, locale)
      try {
        await API.createCase(updatedFormData)
        onAddCase()
        toggle && toggle()
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })

  return (
    <CasesDashboardForm
      formik={formik}
      toggle={toggle}
      locale={locale}
      errorHandler={errorHandler}
      {...rest}
    />
  )
}
