import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface LabelsFields {
  key?: string
  value: string
  locale?: { value?: string; label?: string } | null
}

interface UseLabelsFormOptions {
  initialValues?: LabelsFields
  onSubmit: (
    values: LabelsFields,
    formikHelpers: FormikHelpers<LabelsFields>
  ) => Promise<any>
}

export const useLabelsFormik = (options: UseLabelsFormOptions) => {
  const LabelsSchema = Yup.object().shape({
    key: Yup.string().required("Key is a required field!"),
    value: Yup.string().required("Value is a required field!"),
    locale: Yup.string().nullable().required("Locale is a required field!")
  })

  return useFormik<LabelsFields>({
    initialValues: {
      key: "",
      value: "",
      locale: null,
      ...options.initialValues
    },
    validateOnMount: true,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LabelsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type LabelsFormik = ReturnType<typeof useLabelsFormik>
