import React, { useState } from "react"
import {
  Collapse as RCollapse,
  CardBody,
  Card,
  CollapseProps
} from "reactstrap"
import cs from "classnames"
import { Icon } from "../Icon/Icon"

import "./Collapse.scss"

interface Props extends CollapseProps {}

export const Collapse = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)

  return (
    <div className="Collapse">
      <div className="Collapse__header">
        <h1 className="Collapse__title">{props.title}</h1>
        <button
          className="Collapse__button"
          name="collapse"
          color="primary"
          onClick={toggle}
        >
          <Icon
            className={cs("Collapse__icon", {
              "Collapse__icon-up": isOpen
            })}
            icon="arrow"
          />
        </button>
      </div>
      <RCollapse className="Collapse__wrapper" isOpen={isOpen}>
        <Card className="Collapse__card">
          <CardBody className="Collapse__content">{props.children}</CardBody>
        </Card>
      </RCollapse>
    </div>
  )
}
