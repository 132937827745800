import React, { ReactNode, useState } from "react"
import { FiltersContext, FiltersContextType } from "./FiltersContext"

interface Props {
  children: ReactNode
}

export function FiltersContextProvider(props: Props) {
  const [shouldRefresh, setShouldRefresh] = useState(false)

  const context: FiltersContextType = {
    shouldRefresh,
    setShouldRefresh: (value: boolean) => setShouldRefresh(value)
  }
  return (
    <FiltersContext.Provider value={context}>
      {props.children}
    </FiltersContext.Provider>
  )
}
