import React from "react"
import { useLanguageFormik } from "../../../lib/hooks/formik/useLanguageFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { LanguageForm } from "../LanguageForm/LanguageForm"
import * as API from "../../../api/Api"

interface Props {
  toggle?: () => void
  onAddLanguage: (language: API.AddEditLanguageResponse) => void
}
export const AddLanguage = ({ onAddLanguage, toggle }: Props) => {
  const errorHandler = useErrorHandler()

  const formik = useLanguageFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.createLanguage(values)
        onAddLanguage(res)
        toggle && toggle()
        formikHelpers.resetForm()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })
  return (
    <LanguageForm errorHandler={errorHandler} toggle={toggle} formik={formik} />
  )
}
