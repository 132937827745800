import React from "react"
import { useLoginFormik } from "../../lib/hooks/formik/useLoginFormik"
import { Input } from "../shared/Input/Input"
import { Button } from "../shared/Button/Button"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../shared/ErrorMessage/ErrorMessage"
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext"
import { FormWrapper } from "../shared/FormWrapper/FormWrapper"

import * as API from "../../api/Api"

import "./LoginForm.scss"
import { useTranslation } from "react-i18next"

export const LoginForm = () => {
  const authCtx = useAuthContext()
  const errorHandler = useErrorHandler()

  const formik = useLoginFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.login(values)
        authCtx.login(res)
        formikHelpers.resetForm()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })
  const { t } = useTranslation()
  return (
    <div className="LoginForm">
      <FormWrapper className="LoginForm__wrapper">
        <h1 className="LoginForm__title">Login</h1>
        <form className="LoginForm__form" onSubmit={formik.handleSubmit}>
          <Input
            className="LoginForm__input"
            type="text"
            name="username"
            id="username"
            placeholder={t("User name")}
            value={formik.values.username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.username}
            touched={formik.touched.username}
          />
          <Input
            className="LoginForm__input"
            type="password"
            name="password"
            id="password"
            placeholder={t("Password")}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.password}
            touched={formik.touched.password}
          />
          <Button
            className="LoginForm__button"
            type="submit"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {formik.isSubmitting ? t("LOGGING IN...") : t("LOGIN")}
          </Button>
          <ErrorMessage errorData={errorHandler} onClose={errorHandler.reset} />
        </form>
      </FormWrapper>
    </div>
  )
}
