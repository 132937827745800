import { apiRequest } from "./Api"

export interface Label {
  key?: string
  value?: string
  locale?: string
}
export interface LabelsOfLocaleResponse {
  total_data: number
  rows: Label[]
}

export interface LabelByKeyResponse {
  key: string
  values: {
    value: string
    locale: string
  }[]
}
export interface LabelsResponse {
  [locale: string]: { key: string; value: string }[]
}

export const getAllLabels = async () =>
  apiRequest<undefined, LabelsResponse>("get", "api/labels")

export const createUpdateLabel = async (data: Label) =>
  apiRequest<Label, Label>("post", "api/labels", data)

export const getLabelsOfLocale = async (locale?: string) =>
  apiRequest<string, any>("get", `api/labels/${locale}`)

export const getLabelByKey = async (key?: string) =>
  apiRequest<string, LabelByKeyResponse>("get", `api/labels/${key}`)
