import React from "react"
import { Payload } from "../../LineChart/LineTooltip/LineTooltip"
import { Data } from "../../BarChart/BarChart"
import { useTranslation } from "react-i18next"

import "./Legend.scss"

interface LegendProps {
  payload?: any
  onMouseEnter?: (entry: Payload, index: number) => void
  onMouseLeave?: (entry: Payload, index: number) => void
  onClick?: (e: Payload) => void
  data?: Data[]
  colors?: string[]
}

export const CategoriesLegend = ({
  payload,
  onMouseLeave,
  onClick,
  onMouseEnter,
  data,
  colors,
  ...rest
}: LegendProps) => {
  const { t } = useTranslation()

  return (
    <ul className="Legend">
      <h1 className="Legend__title"> {t("Legend")} </h1>
      {payload &&
        payload?.parentCategories?.map((parent: any) => {
          return (
            <>
              <h1 className="Legend__subtitle">
                {parent.translations?.[0].name}
              </h1>

              {payload?.childCategories?.map((child: any, index: number) => {
                if (parent.id === child.category_parent_id)
                  return (
                    <li
                      key={`legenditem-${index}`}
                      className="Legend__item"
                      onMouseEnter={() =>
                        onMouseEnter && onMouseEnter(child, index)
                      }
                      onMouseLeave={() =>
                        onMouseLeave && onMouseLeave(child, index)
                      }
                      onClick={() => onClick && onClick(child)}
                    >
                      <div
                        className="Legend__indicator"
                        style={{
                          backgroundColor: `${
                            child.color ? child.color : colors?.[index]
                          }`
                        }}
                      ></div>
                      <p className="Legend__text">
                        {child.translations?.[0]?.name}
                      </p>
                    </li>
                  )
              })}
            </>
          )
        })}
    </ul>
  )
}
