import React from "react"
import { ModalBody, ModalHeader, ModalProps } from "reactstrap"
import { UserRow } from "../../../api/User"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { Modal } from "../../shared/Modal/Modal"
import { AddUser } from "../AddUser/AddUser"
import { EditUser } from "../EditUser/EditUser"

import * as API from "../../../api/Api"

import "./UsersModal.scss"
import { useTranslation } from "react-i18next"
interface Props extends ModalProps {
  toggle: () => void
  userId?: number
  onAddUser: (user: UserRow) => void
  onEditUser: (user: UserRow) => void
}

export const UsersModal = ({
  userId,
  onAddUser,
  toggle,
  onEditUser,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  const roles = useApiCall(API.getAllRoles)

  return (
    <Modal className="UsersModal" toggle={toggle} {...rest} centered>
      <ModalHeader className="UsersModal__header">
        {userId ? t("Edit user") : t("Add user")}
      </ModalHeader>
      <ModalBody className="UsersModal__body">
        {userId ? (
          <EditUser
            onEditUser={onEditUser}
            userId={userId}
            roles={roles}
            toggle={toggle}
            {...rest}
          />
        ) : (
          <AddUser
            onAddUser={onAddUser}
            roles={roles}
            toggle={toggle}
            {...rest}
          />
        )}
      </ModalBody>
    </Modal>
  )
}
