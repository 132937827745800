import React, { useEffect, useState } from "react"
import { Alert as RAlert, AlertProps } from "reactstrap"
import { IconDefinition } from "../Icon/Icon.generated"
import { Icon } from "../../../components/shared/Icon/Icon"
import cs from "classnames"

import "./Alert.scss"

export interface Props extends AlertProps {
  onClose?: () => void
  duration?: number
  show: boolean
  icon?: IconDefinition
}

export const Alert = (props: Props) => {
  const [visible, setVisible] = useState<boolean>(false)
  const { className, show, ...rest } = props

  const onDismiss = () => setVisible(false)

  useEffect(() => {
    setVisible(true)
    if (props.duration) {
      let timer = setTimeout(() => {
        onDismiss()
      }, props.duration * 1000)

      return () => clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show])

  if (!visible) return null

  if (props.show === false) return null

  return (
    <RAlert
      className={cs("Alert", className)}
      isOpen={visible}
      toggle={onDismiss}
      {...rest}
    >
      {props.icon && <Icon className="Alert__icon" icon={props.icon} />}
      {props.children}
    </RAlert>
  )
}
