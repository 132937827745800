import { CSSObject } from "@emotion/css"
import { OptionProps, OptionTypeBase, GroupTypeBase } from "react-select"

export const selectStyles = {
  option: (
    base: CSSObject,
    state: OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>
  ) => ({
    ...base,
    backgroundColor: state.isSelected ? "#2e2525" : "#241D1D",
    color: state.isSelected ? "#F26773" : "white",
    cursor: "pointer",
    border: "none",
    paddingLeft: "24px",
    "&:hover": {
      color: "#F26773",
      backgroundColor: "#2e2525"
    },
    fontSize: "15px",
    fontFamily: "Open Sans"
  }),
  control: () => ({
    width: 270,
    height: 40,
    backgroundColor: "#241D1D",
    color: "white",
    borderRadius: 20,
    display: "flex"
  }),
  placeholder: () => ({
    color: "white",
    opacity: 0.5,
    fontFamily: "Open sans",
    fontSize: "15px"
  }),
  dropdownIndicator: (base: CSSObject) => ({
    ...base,
    padding: "16px"
  }),
  valueContainer: (base: any) => ({
    ...base,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontFamily: "Open sans",
    color: "white",
    display: "flex",
    flexWrap: "nowrap",
    paddingLeft: "24px",
    fontSize: "15px"
  }),
  menuList: (base: any) => ({
    ...base,
    padding: "0px"
  }),
  noOptionsMessage: (base: any) => ({
    ...base,
    color: "#F26773",
    backgroundColor: "#241D1D",
    cursor: "not-allowed"
  }),
  input: (base: any) => ({
    ...base,
    color: "white",
    fontFamily: "Open sans",
    fontSize: "15px"
  }),
  singleValue: (base: any) => ({
    ...base,
    color: "white",
    height: "18px"
  }),
  group: (base: any) => ({
    ...base,
    backgroundColor: "#241D1D"
  }),
  groupHeading: (base: any) => ({
    ...base,
    color: "#FFF",
    opacity: 0.5,
    padding: "10px",
    fontFamily: "Open sans"
  })
}

export const multiValueContainer = ({ data }: any) => {
  return `${data.label}${","}` as any
}
