import React from "react"

import { LanguageTable } from "../../components/Language/LanguageTable/LanguageTable"

import "./Language.scss"

export const Language = () => {
  return (
    <div className="Language">
      <LanguageTable />
    </div>
  )
}
