import React, { FocusEvent } from "react"

import { Icon } from "../Icon/Icon"
import { SelectComponentsProps } from "react-select/src/Select"
import { selectStyles, multiValueContainer } from "./selectStyles/selectStyles"
import ReactSelect, {
  components,
  GroupTypeBase,
  OptionTypeBase
} from "react-select"

import "./Select.scss"
import { FormFeedback } from "reactstrap"
import cs from "classnames"
import { useTranslation } from "react-i18next"
export interface Option {
  value: string
  label: string
}

export interface SelectProps extends SelectComponentsProps {
  name: string
  id: string
  options?:
    | readonly (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[]
    | undefined
  placeholder: string
  loading?: boolean
  error?: any
  value?: OptionTypeBase | readonly OptionTypeBase[] | null | undefined
  isMulti: boolean
  isClearable?: boolean
  onChange: (option: Option[] | Option, id: string) => void
  onBlur?: (e: FocusEvent<any>) => void
  className?: string
  closeMenuOnSelect?: boolean
  touched?: boolean
}
export const Select = ({
  name,
  id,
  options,
  placeholder,
  value,
  onChange,
  onBlur,
  loading,
  isMulti,
  error,
  touched,
  isClearable,
  className,
  closeMenuOnSelect,
  ...rest
}: SelectProps) => {
  const { t } = useTranslation()
  return (
    <div className={cs("Select", className)}>
      <ReactSelect
        isClearable={isClearable}
        hideSelectedOptions={false}
        id={id}
        name={name}
        styles={selectStyles}
        closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : false}
        placeholder={loading ? t("Loading...") : placeholder}
        components={{
          DropdownIndicator,
          IndicatorSeparator,
          ClearIndicator,
          MultiValueContainer: multiValueContainer
        }}
        isMulti={isMulti}
        options={options}
        value={value}
        onChange={(option: any) => onChange(option, id)}
        onBlur={onBlur}
        {...rest}
      />
      {error && touched && (
        <FormFeedback className="Input__error">{error}</FormFeedback>
      )}
    </div>
  )
}

function DropdownIndicator(props: any) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        className="Select__icon"
        style={{ cursor: "pointer" }}
        icon="arrow"
      />
    </components.DropdownIndicator>
  )
}
//remove divider
export const IndicatorSeparator = () => null

export const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon className="Select__clear" onClick={props.clear} icon="close" />
    </components.ClearIndicator>
  )
}
