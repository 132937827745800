import React, { useState } from "react"
import { ReportsTable } from "../../components/ReportsTable/ReportsTable"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useReportsRows } from "../../components/ReportsTable/utils/useReportsRows"
import { useTranslation } from "react-i18next"
import * as API from "../../api/Api"

import "./Reports.scss"

export const Reports = () => {
  const { t, i18n } = useTranslation()
  const [pageNumber, setPageNumber] = useState(1)
  const {
    loading: isLoading,
    data: reports,
    error
  } = useApiCall(API.getReports, [pageNumber, i18n.language])
  //table properties
  const columns = [
    { key: "fileName", header: t("file name") },
    { key: "date", header: t("Date"), width: 125 },
    { key: "preview", header: "" },
    { key: "download", header: "" }
  ]
  const rows = useReportsRows(false, undefined, reports?.rows)

  const handlePageChange = (currentPage: number) => {
    setPageNumber(currentPage)
  }

  return (
    <div className="Reports">
      <ReportsTable
        onPageChange={handlePageChange}
        loading={isLoading}
        reports={reports}
        currentPage={pageNumber}
        columns={columns}
        rows={rows}
        error={error}
      />
    </div>
  )
}
