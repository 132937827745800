import { apiRequest } from "./Api"

export interface MOATranslations {
  name: string
  locale: string
  attack_means_id: number
}
interface MOAChildren {
  id: number
  attack_means_parent_id: number
  translations: MOATranslations[]
}
export interface MOARows {
  id: number
  attack_means_parent_id: number | null
  children: MOAChildren[]
  translations: MOATranslations[]
}
export interface MeansOfAttackResponse {
  total_data: number
  rows: MOARows[]
}

export interface MOAWithCountChildren {
  id: number
  cases_count: number
  translations: MOATranslations[]
}

export interface MOAWithCount {
  childrens: MOAWithCountChildren[]
  translations: MOATranslations[]
  id: number
}
export interface MeansOfAttackWithCountResponse {
  means_of_attack: MOAWithCount[]
}

export const getMeansOfAttacks = async (locale: string) =>
  apiRequest<undefined, MeansOfAttackResponse>(
    "get",
    "api/means-of-attack?sortByAsc=id,children.translations.name",
    undefined,
    { locale }
  )
export const getMeansOfAttacksWithCount = async (locale: string) =>
  apiRequest<undefined, MeansOfAttackWithCountResponse>(
    "get",
    "api/means-of-attack/cases-count",
    undefined,
    { locale }
  )
