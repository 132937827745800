import React from "react"
import { InputType } from "reactstrap/lib/Input"
import { Input } from "../Input/Input"
import { TableButton } from "../Table/TableButton/TableButton"
import cs from "classnames"
import "./DynamicInput.scss"
import { FormFeedback } from "reactstrap"

interface Props {
  value?: { link: string; id: number | null }[]
  placeholder: string
  type: InputType | undefined
  name: string
  id: string
  onChange: (inputList: any, index: number) => void
  onAddClick: () => void
  onRemoveClick: (i: number, id: number | null) => void
  className: string
  error?: any
  touched: any
}

export const DynamicInput = ({
  placeholder,
  type,
  value,
  id,
  error,
  touched,
  onAddClick,
  onChange,
  className,
  onRemoveClick
}: Props) => {
  return (
    <div className={cs("DynamicInput", className)}>
      {value?.map((input, i) => {
        return (
          <div className="DynamicInput__container">
            <Input
              className="DynamicInput__input"
              type={type}
              name={input.link}
              id={id}
              value={input.link}
              placeholder={placeholder}
              onChange={(e) => {
                onChange(e, i)
              }}
            />
            {!error ||
              (error.length > 0 && touched && (
                <FormFeedback className="Input__error">
                  {error[i]?.link}
                </FormFeedback>
              ))}
            <div className="DynamicInput__buttons">
              {value.length - 1 === i && (
                <TableButton
                  className="DynamicInput__add"
                  onClick={() => onAddClick()}
                  icon="add"
                  iconclassname="DynamicInput__icon"
                >
                  Add
                </TableButton>
              )}
              {value.length !== 1 && (
                <TableButton
                  className="DynamicInput__remove"
                  onClick={(e: any) => {
                    onRemoveClick(i, input.id)
                  }}
                  icon="close"
                  iconclassname="DynamicInput__close"
                  type="button"
                />
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
