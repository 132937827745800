import { MeansOfAttackWithCountResponse } from "../../api/MeansOfAttack"

export const formatBarChartData = (
  index: number,
  data?: MeansOfAttackWithCountResponse
) => {
  const dataToReturn = (data &&
    data.means_of_attack[index]?.childrens?.length > 0 &&
    data.means_of_attack[index].childrens
      .filter((child) => child.cases_count > 0)
      .map((child) => ({
        value: child.cases_count,
        name: child.translations[0].name
      }))) || [{ name: "", value: 0 }]

  return dataToReturn && dataToReturn.length !== 0
    ? dataToReturn
    : [{ name: "No data", value: 0 }]
}
