import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth"

interface Props {
  width: number
  count: number
}
export const TextPlaceholder = (props: Props) => {
  const width = useWindowWidth()
  return (
    <SkeletonTheme color="#675656" highlightColor="#1C1616">
      <Skeleton
        count={props.count}
        width={width > 800 ? props.width : 300}
        height="18px"
      />
    </SkeletonTheme>
  )
}
