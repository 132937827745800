import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter as Router } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"
/****
 * * IMPORTANT NOTE: Do not change _main.scss_ import order.
 * It needs to be imported before main App component
 ****/
import "./styles/main.scss"
import "./styles/_reset.scss"
//translation lib

import { UIContextProvider } from "./lib/context/UIContext/UIContextProvider"
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider"
import { ConfirmationContextProvider } from "./lib/context/ConfirmationContext/ConfirmationContextProvider"
import { LanguageContextProvider } from "./lib/context/LanguageContext/LanguageContextProvider"
import App from "./App"
import { FiltersContextProvider } from "./lib/context/FiltersContext/FiltersContextProvider"

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AuthContextProvider>
        <UIContextProvider>
          <ConfirmationContextProvider>
            <LanguageContextProvider>
              <FiltersContextProvider>
                <App />
              </FiltersContextProvider>
            </LanguageContextProvider>
          </ConfirmationContextProvider>
        </UIContextProvider>
      </AuthContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
