/* eslint-disable @typescript-eslint/no-unused-vars */
import { CasesDashboardFields } from "../hooks/formik/useCasesDashboardFormik"
import { formatDate } from "./formatDate"
import { prepareArrayData } from "./prepareArrayData"

export const caseFormData = (
  formData: FormData,
  values: CasesDashboardFields,
  locale: string
) => {
  formData.append("start_date", formatDate(values.startDate))
  values.endDate && formData.append("end_date", formatDate(values.endDate))
  formData.append("title", values.title || "")
  formData.append("description", values?.description || "")
  formData.append("outcome", values?.outcome || "")
  formData.append("locale", locale || "")
  // formData.append("country_id", values.country_id?.value as string)
  const res =
    values.links &&
    values?.links.map((link, idx) => {
      return { value: link.link }
    })
  const files = values.files?.forEach((file: any, idx: number) => {
    return formData.append("files", file)
  })
  const deleteFiles = values.files_to_delete?.forEach(
    (file: any, idx: number) => formData.append(`files_to_delete[${idx}]`, file)
  )
  prepareArrayData(formData, {
    attackers: values.attackers,
    means_of_attack: values.meansOfAttacks,
    affected_parties: values.affectedParties,
    categories: values.categories,
    links: res,
    files: values.files?.files
  })
  return formData
}
