import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useLanguageContext } from "../../../../lib/context/LanguageContext/LanguageContext"
import { useApiCall } from "../../../../lib/hooks/useApiCall"
import { Icon } from "../../../shared/Icon/Icon"
import {
  Dropdown as RDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import * as API from "../../../../api/Api"
import cs from "classnames"
import "../LanguageSelector/LanguageSelector.scss"
import { useTranslation } from "react-i18next"
import { HandleErrorState } from "../../../shared/HandleErrorState/HandleErrorState"
import { IconDefinition } from "../../../shared/Icon/Icon.generated"

// const localeIcon: { [key: string]: IconDefinition } = {
//   sq: "sq-flag",
//   en: "en-flag"
// }

const getCountryFlags = (): { [key: string]: IconDefinition } => {
  const url = process.env.REACT_APP_API_URL

  //Montenegro
  if (url?.includes("me"))
    return {
      mg: "me-flag",
      en: "en-flag"
    }
  //Kosovo
  if (url?.includes("xk"))
    return {
      sq: "xk-flag",
      srb: "srb-flag",
      en: "en-flag",
      al: "sq-flag"
    }

  //Albania
  return {
    sq: "sq-flag",
    en: "en-flag"
  }
}

const localeIcon: { [key: string]: IconDefinition } = getCountryFlags()

export const LanguageSelector = () => {
  const { data: languages, error } = useApiCall(API.getLanguages)
  const { changeLanguage } = useLanguageContext()
  const {
    i18n: { language }
  } = useTranslation()
  const history = useHistory()
  const {
    location: { pathname }
  } = history
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string | null>(language)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  const url = process.env.REACT_APP_API_URL
  const handleItemClick = (
    item: { language: string; locale: string },
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const urlPathname = pathname.split("/")
    urlPathname.splice(0, 2)
    const urlAfterLanguage = urlPathname.join("/")
    // setSelectedItem(item.locale)
    changeLanguage(item.locale)
    history.push(`/${item.locale}/${urlAfterLanguage}`)
  }

  useEffect(() => {
    setSelectedItem(language)
  }, [language])

  return (
    <RDropdown
      className={cs("Dropdown", "LanguageSelector")}
      isOpen={dropdownOpen}
      toggle={toggle}
    >
      <HandleErrorState error={error}>
        <DropdownToggle
          className={cs("Dropdown__toggle", {
            Dropdown__isDoubleFlag:
              (selectedItem === "sq" || selectedItem === "srb") &&
              url?.includes("xk")
          })}
        >

          {selectedItem ? (
            <Icon
              icon={localeIcon[selectedItem]}
              className="LanguageSelector__flagIcon"
            />
          ) : (
            languages?.rows?.[0].locale
          )}

          <Icon className="LanguageSelector__icon" icon="arrow" />
        </DropdownToggle>
        <DropdownMenu className={cs("Dropdown__menu")} right>
          {languages?.rows?.map((item) => (
            <DropdownItem
              key={`Dropdown-${item.locale}`}
              className={cs(
                "Dropdown__item",
                "LanguageSelector__item",
                {
                  Dropdown__item_isActive: selectedItem === item.locale
                },
                {
                  Dropdown__isDoubleFlag:
                    (item.locale === "sq" || item.locale === "srb") &&
                    url?.includes("xk")
                }
              )}
              onClick={(e) => {
                handleItemClick(item, e)
              }}
            >

              <Icon
                icon={localeIcon[item.locale]}
                className="LanguageSelector__flagIcon"
              />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </HandleErrorState>
    </RDropdown>
  )
}
