import React from "react"
import { Icon } from "../../shared/Icon/Icon"

import "./LoadingScreen.scss"

export const LoadingScreen = () => {
  return (
    <div className="LoadingScreen">
      <Icon icon="share-foundation-logo" className="LoadingScreen__logo" />
    </div>
  )
}
