import React from "react"
import { formatDataForChart } from "../../lib/helpers/formatDataForChart"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useTranslation } from "react-i18next"
import { PieChart } from "../shared/charts/PieChart/PieChart"

import * as API from "../../api/Api"
import { useWindowWidth } from "../../lib/hooks/useWindowWidth"
import { getChartColors } from "../../lib/helpers/getChartColors"

export const AffectedParties = () => {
  const { i18n } = useTranslation()
  const {
    loading: partiesLoading,
    data: affectedParties,
    error: partiesError
  } = useApiCall(API.getAffectedPartiesWithCount, [i18n.language])

  const affectedPartiesData = formatDataForChart(
    "case_affected_parties",
    affectedParties
  )
  const colors = getChartColors(354)
  const width = useWindowWidth()

  return (
    <PieChart
      id="affectedParties"
      loading={partiesLoading}
      error={partiesError}
      data={affectedPartiesData}
      colors={colors}
      outerRadius={width < 600 ? 100 : 160}
    />
  )
}
