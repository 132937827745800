import React from "react"
import { Button, ButtonProps } from "../../Button/Button"
import cs from "classnames"

import "./TableButton.scss"

export const TableButton = (props: ButtonProps) => {
  const { className, ...rest } = props

  return (
    <Button
      className={cs("TableButton", className)}
      iconclassname="TableButton__icon"
      {...rest}
    />
  )
}
