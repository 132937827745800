import React from "react"
import { UseErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { LanguageFormik } from "../../../lib/hooks/formik/useLanguageFormik"
import { Input } from "../../shared/Input/Input"
import { FormButtons } from "../../shared/FormButtons/FormButtons"

import "./LanguageForm.scss"
import { useTranslation } from "react-i18next"

interface Props {
  loading?: boolean
  errorHandler: UseErrorHandler
  formik: LanguageFormik
  toggle?: () => void
  error?: string
}

export const LanguageForm = ({
  formik,
  error,
  toggle,
  loading,
  errorHandler
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className="LanguageForm">
      <form className="LanguageForm__form" onSubmit={formik.handleSubmit}>
        <Input
          loading={loading}
          className="LanguageForm__input"
          type="text"
          name="language"
          id="language"
          placeholder={t("Language")}
          value={formik.values.language}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.language}
          error={formik.errors.language}
        />
        <Input
          loading={loading}
          className="LanguageForm__input"
          type="text"
          name="locale"
          id="locale"
          placeholder={t("Locale")}
          value={formik.values.locale}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.locale}
          error={formik.errors.locale}
          disabled={!!formik.initialValues.locale}
        />
        <FormButtons
          toggle={toggle}
          disabled={!formik.isValid || formik.isSubmitting}
          isSubmitting={formik.isSubmitting}
        />
        <ErrorMessage
          errorData={errorHandler || error}
          onClose={errorHandler.reset}
        />
      </form>
    </div>
  )
}
