import React from "react"
import { TableButton } from "../../shared/Table/TableButton/TableButton"
import { Case, CaseCategories } from "../../../api/Cases"
import { useConfirmation } from "../../../lib/context/ConfirmationContext/ConfirmationContext"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

export const useCasesDashboardRows = (
  rows?: Case[],
  toggleModal?: () => void,
  handleEditClick?: (caseId: number) => void,
  handleDeleteClick?: (caseId: number) => void,
  deleting?: boolean,
  caseId?: number
) => {
  const { t } = useTranslation()
  const confirmation = useConfirmation()

  if (!rows) return

  return (
    rows?.map((row) => ({
      title: row?.translations[0]?.title,
      category: row?.case_categories
        ?.map(
          (category: CaseCategories) =>
            category.case_category.translations[0].name
        )
        .join(", "),
      // country: row.country?.translations[0].name,
      date: format(new Date(row.start_date), "MM/dd/yyyy"),
      edit: (
        <TableButton
          icon="edit"
          onClick={() => {
            toggleModal && toggleModal()
            handleEditClick && handleEditClick(row?.translations?.[0]?.case_id)
          }}
        >
          {t("Edit")}
        </TableButton>
      ),
      delete: (
        <TableButton
          onClick={() => {
            confirmation
              .confirm(t("Delete"))
              .then(
                () =>
                  handleDeleteClick &&
                  handleDeleteClick(row.translations[0].case_id)
              )
          }}
        >
          {deleting && row.translations[0].case_id === caseId
            ? t("Deleting")
            : t("Delete")}
        </TableButton>
      )
    })) || []
  )
}
