import { apiRequest } from "./Api"
export interface ReportFile {
  id: number
  encoding: string
  filename: string
  image_url: string
  mimetype: string
  object_id: number
  object_type: string
}

export interface ReportsTranslations {
  locale: string
  name: string
  report_id: number
}
export interface RUser {
  email: string
  id: number
  role_id: number
  username: string
}
export interface ReportsRows {
  id: number
  date: number
  user_id: number
  report_file: ReportFile | ReportFile[]
  translations: ReportsTranslations[]
  user: RUser
}
export interface ReportsResponse {
  total_data: number
  rows: ReportsRows[]
}
export interface ReportsInput {
  pageNumber: number
}
export interface CreateReportResponse {
  report: ReportsRows
}
export const getReports = async (pageNumber: number, locale: string) => {
  return apiRequest<undefined, ReportsResponse>(
    "get",
    `api/reports?limit=10&page=${pageNumber}&relations=translations,report_file`,
    undefined,
    { locale }
  )
}

export const createReport = async (data: FormData) =>
  apiRequest<FormData, CreateReportResponse>("post", "api/reports", data)

export const deleteReport = async (id: number, locale?: string) =>
  apiRequest<FormData, CreateReportResponse>(
    "delete",
    `api/reports/${id}`,
    undefined,
    locale ? { locale } : undefined
  )
