import React from "react"

import { ReportsRows as ReportsRowsProps } from "../../../api/Reports"
import { TableButton } from "../../shared/Table/TableButton/TableButton"
import FileSaver from "file-saver"
import { useConfirmation } from "../../../lib/context/ConfirmationContext/ConfirmationContext"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

export const useReportsRows = (
  hasDelete: boolean,
  handleDeleteClick?: (id: number) => void,
  rows?: ReportsRowsProps[],
  reportId?: number,
  deleting?: boolean
) => {
  const confirmation = useConfirmation()
  const { t } = useTranslation()
  if (!rows || rows?.length < 0) return
  const saveFile = (filePath: string) => {
    FileSaver.saveAs(
      process.env.REACT_APP_API_URL + "/public/uploads/pictures/" + filePath,
      filePath
    )
  }

  return rows?.map((row) => {
    return {
      fileName: row?.translations[0]?.name,
      date: format(new Date(row.date), "MM/dd/yyyy"),
      preview: (
        <TableButton
          href={`${process.env.REACT_APP_API_URL}/public/uploads/pictures/${
            Array.isArray(row.report_file)
              ? row.report_file[0].filename
              : row?.report_file?.filename
          }`}
          icon="preview"
          target="_blank"
          download
        >
          {t("Preview")}
        </TableButton>
      ),
      download: (
        <TableButton
          icon="more"
          onClick={() =>
            saveFile(
              Array.isArray(row.report_file)
                ? row.report_file[0].filename
                : row?.report_file?.filename
            )
          }
          download
        >
          {t("Download")}
        </TableButton>
      ),
      delete: hasDelete && (
        <TableButton
          onClick={() =>
            confirmation
              .confirm(t("Delete"))
              .then(() => handleDeleteClick && handleDeleteClick(row.id))
          }
        >
          {deleting && row.id === reportId ? t("deleting") : t("Delete")}
        </TableButton>
      )
    }
  })
}
