import React, { useCallback, useEffect, useState } from "react"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { Table } from "../../shared/Table/Table"
import { TableWrapper } from "../../shared/TableWrapper/TableWrapper"
import { useLanguageRows } from "./utils/useLanguageRows"
// import { AddNewButton } from "../../shared/AddNewButton/AddNewButton"
import { LanguageModal } from "../LanguageModal/LanguageModal"
import { useTranslation } from "react-i18next"

import * as API from "../../../api/Api"

import "./LanguageTable.scss"

export const LanguageTable = () => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [languages, setLanguages] = useState<API.LanguagesResponse>()
  // const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [langId, setLangId] = useState<number>()

  const errorHandler = useErrorHandler()

  const getLanguages = useCallback(async () => {
    try {
      const languages = await API.getLanguages()
      setLanguages(languages)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getLanguages()
  }, [getLanguages])

  const handleEditClick = (langId: number) => {
    setLangId(langId)
  }

  const handleAddLanguage = (language: API.AddEditLanguageResponse) => {
    setLanguages((prevState) => {
      if (!prevState) return { total_data: 1, rows: [language.language] }
      return {
        ...prevState,
        rows: [...prevState?.rows, language.language]
      }
    })
  }

  // const handleDeleteLanguage = async (locale: string, langId: number) => {
  //   try {
  //     setIsDeleting(true)
  //     setLangId(langId)
  //     await API.deleteLanguage(locale)
  //   } catch (e) {
  //     errorHandler.handleError(e)
  //   } finally {
  //     setLangId(langId)
  //     setLanguages((prevState) => {
  //       if (prevState)
  //         return {
  //           ...prevState,
  //           rows: prevState.rows.filter((row) => row.locale !== locale)
  //         } as API.LanguagesResponse
  //     })
  //     setIsDeleting(false)
  //   }
  // }
  const handleEditLanguage = (language: API.AddEditLanguageResponse) => {
    setLanguages((prevState) => {
      if (!prevState) return { total_data: 1, rows: [language.language] }

      return {
        total_data: prevState.total_data + 1,
        rows: prevState.rows.map((item) => {
          if (item.id === language.language.id) {
            return language.language
          }
          return item
        })
      }
    })
  }

  const toggleModal = () => {
    setShowModal(!showModal)
    setLangId(undefined)
  }

  //table properties
  const columns = [
    { key: "language", header: t("Languages") },
    { key: "locale", header: t("Locale") },
    { key: "edit", header: "", width: 125 }
    // { key: "delete", header: "", width: 125 }
  ]

  const rows = useLanguageRows(
    handleEditClick,
    // handleDeleteLanguage,
    toggleModal,
    // isDeleting,
    langId,
    languages?.rows
  )

  return (
    <div className="LanguageTable">
      {/* <AddNewButton onClick={toggleModal} /> */}
      <TableWrapper>
        <Table loading={loading} columns={columns} rows={rows} />
        <LanguageModal
          className="Labels__modal"
          isOpen={showModal}
          toggle={toggleModal}
          langId={langId}
          onAddLanguage={handleAddLanguage}
          onEditLanguage={handleEditLanguage}
          centered
        />
      </TableWrapper>
    </div>
  )
}
