import React from "react"

import { FilePond as ReactFilePond, FilePondProps } from "react-filepond"

import "filepond/dist/filepond.min.css"
import { FilePondFile } from "filepond"
import FileSaver from "file-saver"
import cs from "classnames"
import "./FileInput.scss"
import { useTranslation } from "react-i18next"

interface Props extends FilePondProps {}

export const FileInput = ({ allowDrop, files, ...rest }: Props) => {
  const handleFileDownload = (file: FilePondFile) => {
    FileSaver.saveAs(
      process.env.REACT_APP_API_URL +
        "/public/uploads/pictures/" +
        file.filename,
      file.filename
    )
  }
  const { t } = useTranslation()
  return (
    <>
      <ReactFilePond
        className={cs("FileInput", { FileInput__disabled: !allowDrop })}
        allowDrop={allowDrop}
        onactivatefile={handleFileDownload}
        labelIdle={`<div class="filepond--logo-wrapper"><div class="filepond--label-logo"></div><span class="filepond--label-action"> ${t(
          "Add file"
        )} </span></div>`}
        files={files}
        server={{
          load: (source, load, error, progress, abort, headers) => {
            var myRequest = new Request(source)
            fetch(myRequest).then(function (response) {
              response.blob().then(function (myBlob) {
                load(myBlob)
              })
            })
          }
        }}
        {...rest}
      />
      {!allowDrop && files?.length === 0 ? (
        <div style={{ opacity: 0.7 }}>No files!</div>
      ) : (
        ""
      )}
    </>
  )
}
