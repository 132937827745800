import React from "react"
import { useUsersFormik } from "../../../lib/hooks/formik/useUsersFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { Roles, UsersForm } from "../UsersForm/UsersForm"

import * as API from "../../../api/Api"

interface Props {
  onAddUser: (user: API.UserRow) => void
  roles: Roles
  toggle?: () => void
}
export const AddUser = ({ onAddUser, roles, toggle, ...rest }: Props) => {
  const errorHandler = useErrorHandler()

  const formik = useUsersFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.createUser({
          username: values.username,
          password: values.password,
          email: values.email,
          role_id: parseInt(values.role_id?.value || "")
        })
        onAddUser && onAddUser(res.user)
        toggle && toggle()
        formikHelpers.resetForm()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })
  return (
    <UsersForm
      errorHandler={errorHandler}
      formik={formik}
      roles={roles}
      toggle={toggle}
      {...rest}
    />
  )
}
