import React from "react"
import { CasesDashboardFormik } from "../../../lib/hooks/formik/useCasesDashboardFormik"
import { UseErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { Input } from "../../shared/Input/Input"
import { DatePicker } from "../../DatePicker/DatePicker"
import { DynamicInput } from "../../shared/DynamicInput/DynamicInput"
import { FormButtons } from "../../shared/FormButtons/FormButtons"
import { FileInput } from "../../shared/FileInput/FileInput"
import { FiltersSelect } from "../../FiltersSelect/FiltersSelect"
import { FilePondFile } from "filepond"
import { findCommonElements } from "../../../lib/helpers/findCommonElements"
import { CaseFiles } from "../../../api/Api"

import * as API from "../../../api/Api"

import "./CasesDashboardForm.scss"
import { useTranslation } from "react-i18next"

interface Props {
  formik: CasesDashboardFormik
  errorHandler?: UseErrorHandler
  toggle?: () => void
  labelLoading?: boolean
  error?: string
  caseFiles?: CaseFiles[]
  locale: string
}

export const CasesDashboardForm = ({
  formik,
  errorHandler,
  labelLoading,
  toggle,
  locale,
  caseFiles,
  error
}: Props) => {
  const handleChangeOnLink = (e: any, i: number) => {
    const { value } = e.target
    formik.setFieldValue(
      "links",
      formik.values.links.map((link, index) =>
        i === index ? { link: value } : link
      ),
      true
    )
  }
  const { t } = useTranslation()
  const handleAddLink = () => {
    formik.setFieldValue("links", [...formik.values.links, { link: "" }], true)
  }

  const handleRemoveLink = (i: any, id: number | null) => {
    const list = [...formik.values.links]
    list.splice(i, 1)
    formik.setFieldValue("links", list, true)
  }

  const handleFileDelete = (error: any, file: FilePondFile) => {
    if (!caseFiles) return
    const deletedFileId = caseFiles
      .map((case_file) => {
        if (case_file.filename !== file.filename) return undefined
        return case_file.id
      })
      .filter((file) => file !== undefined)
    if (!deletedFileId) return
    const sameElement = findCommonElements(
      deletedFileId,
      formik.values.files_to_delete
    )
    if (sameElement) return
    formik.setFieldValue("files_to_delete", [
      ...formik.values.files_to_delete,
      ...deletedFileId
    ])
  }

  const handleUpdateFiles = (fileItems: FilePondFile[]) => {
    const files = fileItems.map((fileItem) => {
      if (!fileItem.file.lastModified)
        return new File([fileItem.file], fileItem.file.name)
      return fileItem.file
    })
    formik.setFieldValue("files", files)
  }

  return (
    <div className="CasesDashboardForm">
      <form className="CasesDashboardForm__form" onSubmit={formik.handleSubmit}>
        <div className="CasesDashboardForm__columns">
          <div className="CasesDashboardForm__first_column">
            <Input
              className="CasesDashboardForm__input"
              loading={labelLoading}
              type="text"
              name="title"
              id="title"
              placeholder={t("Title")}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.errors.title}
              touched={formik.touched.title}
            />
            <FiltersSelect
              identifier="attacker_id"
              className="CasesDashboardForm__input"
              name="attackers"
              selectId="attackers"
              placeholder={t("Attackers")}
              locale={locale}
              apiRequest={API.getAllAttackers}
              value={formik.values.attackers}
              touched={formik.touched.attackers}
              error={formik.errors.attackers}
              onChange={(option) => {
                formik.setFieldValue("attackers", option, true)
                formik.setFieldTouched("attackers")
              }}
            />

            <DatePicker
              className="CasesDashboardForm__input"
              onChange={(startDate: any) => {
                formik.setFieldValue("startDate", startDate, true)
                formik.setFieldTouched("startDate")
              }}
              endDate={formik.values.endDate}
              startDate={formik.values.startDate}
              placeholder={t("Start date")}
              selectsRange={false}
              error={formik.errors.startDate}
              touched={formik.touched.startDate}
            />
            <FiltersSelect
              identifier="attack_means_id"
              className="CasesDashboardForm__input"
              placeholder={t("Means of attack")}
              name="meansOfAttacks"
              selectId="meansOfAttacks"
              locale={locale}
              apiRequest={API.getMeansOfAttacks}
              onChange={(option) => {
                formik.setFieldValue("meansOfAttacks", option, true)
                formik.setFieldTouched("meansOfAttacks")
              }}
              value={formik.values.meansOfAttacks}
              touched={formik.touched.meansOfAttacks}
              error={formik.errors.meansOfAttacks}
            />

            <Input
              className="CasesDashboardForm__outcome"
              loading={labelLoading}
              type="text"
              name="outcome"
              id="outcome"
              placeholder={t("Outcome")}
              value={formik.values.outcome}
              onChange={formik.handleChange}
              touched={formik.touched.outcome}
              onBlur={formik.handleBlur}
              error={formik.errors.outcome}
            />
            <FileInput
              name="files"
              files={formik.values.files}
              allowDrop
              allowMultiple
              onupdatefiles={(fileItems) => handleUpdateFiles(fileItems)}
              onremovefile={(error, file) => handleFileDelete(error, file)}
            />
          </div>
          <div className="CasesDashboardForm__second_column">
            <FiltersSelect
              identifier="affected_party_id"
              className="CasesDashboardForm__input"
              name="affectedParties"
              selectId="affectedParties"
              placeholder={t("Affected parties")}
              locale={locale}
              apiRequest={API.getAllAffectedParties}
              onChange={(option) => {
                formik.setFieldValue("affectedParties", option, true)
                formik.setFieldTouched("affectedParties")
              }}
              value={formik.values.affectedParties}
              touched={formik.touched.affectedParties}
              error={formik.errors.affectedParties}
            />
            <FiltersSelect
              identifier="category_id"
              className="CasesDashboardForm__input"
              apiRequest={API.getAllCategories}
              placeholder={t("Category")}
              name="categories"
              selectId="categories"
              locale={locale}
              value={formik.values.categories}
              onChange={(option) => {
                formik.setFieldValue("categories", option, true)
                formik.setFieldTouched("categories")
              }}
              touched={formik.touched.categories}
              error={formik.errors.categories}
            />
            {/* <FiltersSelect
              identifier="country_id"
              className="CasesDashboardForm__input"
              apiRequest={API.getAllCountries}
              placeholder={t("Country")}
              name="country_id"
              isMulti={false}
              selectId="country_id"
              locale={locale}
              value={formik.values.country_id}
              onChange={(option) => {
                formik.setFieldValue(
                  "country_id",
                  { value: option.value, label: option.label },
                  true
                )
                formik.setFieldTouched("country_id")
              }}
              touched={formik.touched.country_id}
              error={formik.errors.country_id}
            /> */}

            <DatePicker
              className="CasesDashboardForm__input"
              onChange={(endDate: any) => {
                formik.setFieldValue("endDate", endDate, true)
                formik.setFieldTouched("endDate")
              }}
              endDate={formik.values.endDate}
              placeholder={t("End date")}
              selectsRange={false}
              error={formik.errors.endDate}
              touched={formik.touched.endDate}
            />

            
            <DynamicInput
              className="CasesDashboardForm__input"
              placeholder={t("Links")}
              type="text"
              name="links"
              id="links"
              value={formik.values.links}
              error={formik.errors.links}
              touched={formik.touched.links}
              onChange={(e, i) => {
                handleChangeOnLink(e, i)
                formik.setFieldTouched("links")
              }}
              onAddClick={() => {
                handleAddLink()
              }}
              onRemoveClick={(i, id) => {
                handleRemoveLink(i, id)
              }}
            />
          </div>
        </div>
        <Input
              loading={labelLoading}
              className="CasesDashboardForm__description"
              inputClassName="CasesDashboardForm__description_input"
              wrapperClassName="CasesDashboardForm__description_wrapper"
              type="textarea"
              name="description"
              id="description"
              placeholder={t("Description")}
              value={formik.values.description}
              onChange={formik.handleChange}
              touched={formik.touched.description}
              onBlur={formik.handleBlur}
              error={formik.errors.description}
            />
        <FormButtons
          toggle={toggle}
          disabled={!formik.isValid || formik.isSubmitting}
          isSubmitting={formik.isSubmitting}
        />
        <ErrorMessage errorData={errorHandler} onClose={errorHandler?.reset} />
      </form>
    </div>
  )
}
