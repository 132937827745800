import Axios from "axios"

export async function apiRequest<D = {}, R = unknown>(
  method: "get" | "delete" | "head" | "options" | "post" | "put" | "patch",
  path: string,
  input?: D,
  options?: any,
  params?: any
) {
  const res = await Axios.request<R>({
    url: `${process.env.REACT_APP_API_URL}/${path}`,
    method: method,
    data: input,
    headers: options,
    params: params
  })
  return res.data
}

export * from "./User"
export * from "./Reports"
export * from "./Cases"
export * from "./AffectedParties"
export * from "./Attackers"
export * from "./Category"
export * from "./MeansOfAttack"
export * from "./Language"
export * from "./Labels"
export * from "./Pages"
export * from "./Countries"
