import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody, ModalHeader, ModalProps } from "reactstrap"
import { CreateReportResponse } from "../../../api/Reports"
import { Modal } from "../../shared/Modal/Modal"
import { ReportsForm } from "../ReportsForm/ReportsForm"

import "./ReportsModal.scss"
interface Props extends ModalProps {
  toggle: () => void
  locale?: string
  onAddReport: (report: CreateReportResponse) => void
}

export const ReportsModal = ({
  isOpen,
  locale,
  toggle,
  onAddReport
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal className="ReportsModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="ReportsModal__header">
        {t("add report")}
      </ModalHeader>
      <ModalBody className="ReportsModal__body">
        <ReportsForm
          toggle={toggle}
          locale={locale}
          onAddReport={onAddReport}
        />
      </ModalBody>
    </Modal>
  )
}
