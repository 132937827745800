import React, { useState } from "react"
import { Icon } from "../../shared/Icon/Icon"
import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext"
import { Menu } from "../../Menu/Menu"
import { useTranslation } from "react-i18next"
import { LanguageSelector } from "./LanguageSelector/LanguageSelector"
import { ThemeNav } from "../../shared/ThemeNav/ThemeNav"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { NavLink } from "react-router-dom"
import * as API from "../../../api/Api"
import cs from "classnames"

import "./NavigationMenu.scss"
import { useFiltersContext } from "../../../lib/context/FiltersContext/FiltersContext"

export const NavigationMenu = () => {
  const {
    t,
    i18n,
    i18n: { language }
  } = useTranslation()
  const [showHideMobileMenu, setShowHideMobileMenu] = useState<boolean>(false)
  const { loading, data: cases, error } = useApiCall(API.getAllCases, [
    undefined,
    undefined,
    i18n.language
  ])
  const authCtx = useAuthContext()

  /**
   * ShouldRefresh state is used to manage when should the page of CASES should refresh
   * Example we have filtered in the Cases page, then if we press the navlink cases it should refresh and get all the data
   */
  const { setShouldRefresh, shouldRefresh } = useFiltersContext()

  const handleClick = () => setShowHideMobileMenu(!showHideMobileMenu)

  const closeMobileMenu = () => setShowHideMobileMenu(false)

  const pages = [
    { page: t("METHODOLOGY"), path: `/${language}/methodology` },
    { page: t("ABOUT"), path: `/${language}/about` }
  ]
  return (
    <div className="NavigationMenu">
      <ul
        className={cs(
          "NavigationMenu__nav_options",
          showHideMobileMenu && "active"
        )}
      >
        <ThemeNav
          page={t("HOME")}
          to={`/${language}/`}
          closeMobileMenu={closeMobileMenu}
        />
        <li
          key="data"
          className="NavigationMenu__option"
          onClick={() => closeMobileMenu()}
        >
          <NavLink
            to={`/${language}/data`}
            className="NavigationMenu__nav_link"
            activeClassName="NavigationMenu__nav_active"
            onClick={() => setShouldRefresh(!shouldRefresh)}
          >
            {t("DATA")}
            <span className="NavigationMenu__total">
              {error ? "" : loading ? "(...)" : `(${cases?.total_data})`}
            </span>
          </NavLink>
        </li>
        {pages.map((page) => (
          <ThemeNav
            key={page.page}
            page={page.page}
            to={page.path}
            closeMobileMenu={closeMobileMenu}
          />
        ))}

        {!authCtx.isAuthenticated ? (
          <ThemeNav
            page={t("LOGIN")}
            to={`/${language}/login`}
            closeMobileMenu={closeMobileMenu}
          />
        ) : (
          <li className="NavigationMenu__option">
            <Menu />
          </li>
        )}

        <li className="NavigationMenu__option">
          <LanguageSelector />
        </li>
      </ul>
      <div className="NavigationMenu__mobile_menu" onClick={handleClick}>
        <Icon
          className="NavigationMenu__icon"
          icon={showHideMobileMenu ? "close" : "menu"}
        />
      </div>
    </div>
  )
}
