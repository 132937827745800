import React from "react"
import { TableButton } from "../../shared/Table/TableButton/TableButton"
import { Case, CaseCategories } from "../../../api/Cases"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

export const useCasesRows = (
  rows?: Case[],

  handleRowClick?: (caseId: number) => void
) => {
  const { t } = useTranslation()

  return (
    rows?.map((row) => ({
      title: row?.translations[0]?.title,
      category: row?.case_categories
        ?.map(
          (category: CaseCategories) =>
            category.case_category.translations[0].name
        )
        .join(", "),
      // country: row.country?.translations[0].name,
      date: format(new Date(row.start_date), "MM/dd/yyyy"),
      action: (
        <>
          <TableButton
            icon="more"
            onClick={() => {
              handleRowClick && handleRowClick(row?.translations?.[0]?.case_id)
            }}
          >
            {t("Read More")}
          </TableButton>
        </>
      )
    })) || []
  )
}
