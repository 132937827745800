import React from "react"
import { DatePickerInput } from "./DatePickerInput/DatePickerInput"
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker"
import { FormFeedback } from "reactstrap"
import cs from "classnames"
import "react-datepicker/dist/react-datepicker.css"
import "./DatePicker.scss"
interface Props extends ReactDatePickerProps {
  onChange: (dates: [Date, Date]) => void
  error?: string
  className?: string
  placeholder: string
  touched?: boolean
  isClearable?: boolean
}
export const DatePicker = (props: Props) => {
  return (
    <div
      className={cs("DatePicker", props.className)}
      style={{ position: "relative" }}
    >
      <ReactDatePicker
        isClearable={true}
        selected={props.startDate || props.endDate}
        startDate={props.startDate}
        endDate={props.endDate}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText={props.placeholder}
        customInput={<DatePickerInput className={props.className} />}
        selectsRange={props.selectsRange}
        {...props}
      />
      {props.error && props.touched && (
        <FormFeedback>{props.error}</FormFeedback>
      )}
    </div>
  )
}
