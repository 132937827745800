import { CaseByIdResponse } from "../../api/Cases"
export const FILES_PATH =
  "https://api.share-monitoring.k-clients.com/public/uploads/pictures"
export const formatInitialValues = (initialValues?: CaseByIdResponse) => {
  if (!initialValues) return
  const caseById = initialValues.case
  const caseByIdTranslations = caseById?.translations?.[0]

  return {
    title: caseByIdTranslations?.title,
    outcome: caseByIdTranslations?.outcome,
    description: caseByIdTranslations?.description,
    affectedParties: caseById.case_affected_parties.map((affectedParty) => ({
      label: affectedParty.case_affected_party.translations[0].name,
      value: affectedParty.case_affected_party.translations[0].affected_party_id.toString()
    })),
    attackers: caseById.case_attackers.map((attacker) => ({
      label: attacker.case_attacker.translations[0].name,
      value: attacker.case_attacker.translations[0].attacker_id.toString()
    })),
    // country_id: {
    //   label: caseById?.country?.translations[0].name,
    //   value: caseById?.country?.translations[0].country_id.toString()
    // },
    categories: caseById.case_categories.map((category) => ({
      label: category.case_category.translations[0].name,
      value: category.case_category.translations[0].category_id.toString()
    })),
    meansOfAttacks: caseById.case_means_of_attack.map((meanOfAttack) => ({
      label: meanOfAttack.case_mean_of_attack.translations[0].name,
      value: meanOfAttack.case_mean_of_attack.translations[0].attack_means_id.toString()
    })),
    startDate: caseById.start_date ? new Date(caseById.start_date) : null,
    endDate: caseById.end_date ? new Date(caseById.end_date) : null,
    links: caseById.links.map((link) => {
      return { link: link.link, id: link.id }
    }),
    files: caseById.case_files.map((caseFile) => caseFile.filename),
    files_to_delete: []
  }
}
