import React from "react"
import cs from "classnames"
import "./ModalParagraph.scss"

interface Props {
  title: string
  children?: React.ReactChild | JSX.Element[]
  className?: string
  contentClassName?: string
}
export const ModalParagraph = ({
  title,
  children,
  className,
  contentClassName
}: Props) => {
  return (
    <div className={cs("ModalParagraph", className)}>
      <h1 className="ModalParagraph__title">{title}</h1>
      <p className={cs("ModalParagraph__content", contentClassName)}>
        {children}
      </p>
    </div>
  )
}
