import { apiRequest } from "./Api"

export interface User {
  id: number
  email: string
  username: string
  role: string
}
export interface LoginRegisterResponse {
  access_token: string
  error?: string
  expires_in: string
  user: User
}

export interface RegisterInput {
  username: string
  email: string
  password: string
}

export interface LoginInput {
  username: string
  password: string
}

export interface UserDetailsResponse {
  user: User
}

export interface UserRow {
  id: number
  username: string
  email: string
  role_id: number
}
export interface UsersResponse {
  total_data: number
  rows: UserRow[]
}
export interface CreateUserResponse {
  user: UserRow
}

export interface RolesRows {
  id: number
  name: string
}
export interface RolesResponse {
  total_data: number
  rows: RolesRows[]
}
export interface CreateUserInput {
  username: string
  password?: string
  email: string
  role_id: number
}

export interface UserByIdResponse {
  user: UserRow
}

export interface UpdateUserInput {
  username: string
  email: string
  current_password: string
  password: string
  role_id: number
}
export interface UpdateUserResponse {
  user: UserRow
}
export interface DeleteUserResponse {
  user: UserRow
}

export const register = async (data: RegisterInput) =>
  apiRequest<RegisterInput, LoginRegisterResponse>("post", "api/register", data)

export const login = async (data: LoginInput) =>
  apiRequest<LoginInput, LoginRegisterResponse>("post", "api/login", data)

export const getUserDetails = async () =>
  apiRequest<undefined, UserDetailsResponse>("get", "api/users/me")

export const getAllUsers = async (pageNumber: number) =>
  apiRequest<undefined, UsersResponse>(
    "get",
    `api/users?limit=10&page=${pageNumber}`
  )
export const createUser = async (data: CreateUserInput) =>
  apiRequest<CreateUserInput, CreateUserResponse>("post", `api/users`, data)
export const getUserById = async (id?: number) => {
  return apiRequest<number, UserByIdResponse>("get", `api/users/${id}`)
}

export const getAllRoles = async () =>
  apiRequest<undefined, RolesResponse>("get", "api/roles")

export const updateUser = async (data: UpdateUserInput, userId: number) => {
  return apiRequest<UpdateUserInput, UpdateUserResponse>(
    "put",
    `api/users/${userId}`,
    data
  )
}
export const deleteUser = async (userId: number) =>
  apiRequest<number, DeleteUserResponse>("delete", `api/users/${userId}`)
