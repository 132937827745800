import React from "react"
import { Button, ButtonProps } from "../Button/Button"
import { useTranslation } from "react-i18next"
import cs from "classnames"

import "./AddNewButton.scss"

export const AddNewButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      className={cs("AddNewButton", props.className)}
      iconclassname="AddNewButton__icon"
      icon="add"
      {...props}
    >
      {t("Add new")}
    </Button>
  )
}
