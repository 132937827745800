export const fillValueWithData = (value: any[], data: any) => {
  if (Array.isArray(data)) {
    data.map((item) => {
      return value.push(item)
    })
    return
  }

  return value.push(data)
}
