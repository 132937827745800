import React from "react"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useTranslation } from "react-i18next"
import { PieChart } from "../shared/charts/PieChart/PieChart"

import * as API from "../../api/Api"
import { getChartColors } from "../../lib/helpers/getChartColors"
import { formatCategoriesForChart } from "../../lib/helpers/formatCategoriesForChart"

interface Props {
  width: number
}

export const Categories = ({ width }: Props) => {
  const { i18n } = useTranslation()
  const {
    loading: categoriesLoading,
    data: categories,
    error: categoriesError
  } = useApiCall(API.getCategoriesWithCount, [i18n.language])
  const colors = getChartColors(354)

  const categoriesData = formatCategoriesForChart(
    "categories",
    categories?.childCategories
  )

  return (
    <PieChart
      id="categories"
      loading={categoriesLoading}
      error={categoriesError}
      data={categoriesData}
      allCategories={categories}
      colors={colors}
      outerRadius={width < 600 ? 100 : 160}
    />
  )
}
