import React from "react"
import { useTranslation } from "react-i18next"
import { LabelsResponse } from "../../../../api/Labels"

import { TableButton } from "../../../shared/Table/TableButton/TableButton"

export const useLabelsRows = (
  handleEditClick: (key: string) => void,
  rows?: LabelsResponse
) => {
  const { t } = useTranslation()
  if (!rows) return
  const localesObject = {}

  const locales = Object.keys(rows)

  locales.forEach((locale) => {
    rows[locale].map(({ key, value }) => {
      if ((localesObject as any).hasOwnProperty(key)) {
        return ((localesObject as any)[key] = {
          ...(localesObject as any)[key],
          [locale]: value
        })
      }
      return ((localesObject as any)[key] = { [locale]: value })
    })
  })

  return Object.entries(localesObject)?.map(([key, values]) => {
    let rowProperties = {}
    locales.forEach((locale) => {
      if (!rowProperties.hasOwnProperty(locale)) {
        return (rowProperties = {
          ...rowProperties,
          key,
          [locale]: (values as any)[locale],
          actions: (
            <TableButton icon="edit" onClick={() => handleEditClick(key)}>
              {t("Edit")}
            </TableButton>
          )
        })
      }
    })

    return rowProperties
  })
}
