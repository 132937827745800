import React from "react"
import cs from "classnames"

import "./Section.scss"

interface Props {
  className?: string
  title?: string
  children?: JSX.Element[] | JSX.Element
  label?: string
  title2?: string
  subtitle?: string
}

export const Section = (props: Props) => {
  const { children, className, title, label, title2, subtitle, ...rest } = props
  const classNames = cs("Section", props.className)
  if (!children) return <div></div>
  return (
    <div className={classNames} {...rest}>
      {title && (
        <div className="Section__title-positon">
          <div className="Section__title-description">
            <h1 className="Section__title">{title}</h1>
            <h1 className="Section__title-style"></h1>
          </div>
        </div>
      )}
      <p className="Section__subtitle">{subtitle}</p>

      <div className="Section__wrapper">
        <div className="Section__children">{children}</div>
      </div>
    </div>
  )
}
