import React, { useEffect, useState } from "react"
import { ContentState, EditorState } from "draft-js"
import { Editor as DraftEditor } from "react-draft-wysiwyg"
import { useTranslation } from "react-i18next"
import { PageRow } from "../../../api/Pages"
import { Button } from "../Button/Button"
import htmlToDraft from "html-to-draftjs"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import "./Editor.scss"

interface Props {
  page: PageRow
  onSubmit: (pageId: number, editorState: EditorState) => void
  isSubmitting: boolean
  placeholder?: string
  title: string
  id: number
}

export const Editor = ({ page, isSubmitting, onSubmit, id, title }: Props) => {
  const { t } = useTranslation()
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  )

  useEffect(() => {
    const blocksFromHTML = htmlToDraft(page.translations[0]?.content)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    setEditorState(EditorState.createWithContent(state))
  }, [page])

  const uploadCallback = (file: File) => {
    return new Promise((resolve, reject) => {
      if (file) {
        let reader = new FileReader()
        reader.onload = (e) => {
          resolve({ data: { link: e?.target?.result } })
        }
        reader.readAsDataURL(file)
      }
    })
  }
  return (
    <div className="Editor">
      <h1 className="Editor__title">{title}</h1>
      <DraftEditor
        key={id}
        editorState={editorState}
        placeholder={t("Loading...")}
        wrapperClassName="Editor__wrapper"
        editorClassName="Editor__editor"
        toolbarClassName="Editor__toolbar"
        onEditorStateChange={setEditorState}
        toolbar={{
          image: {
            urlEnabled: false,
            uploadEnabled: true,
            alignmentEnabled: true, // Whether to display the arrange button is equivalent to text-align
            uploadCallback: uploadCallback,
            previewImage: true,
            inputAccept: "image/*",
            alt: { present: false, mandatory: false }
          }
        }}
      />

      <Button
        className="Editor__submit"
        type="submit"
        onClick={() => onSubmit(page.translations[0].page_id, editorState)}
        disabled={isSubmitting}
      >
        {isSubmitting ? t("Saving") : t("Save")}
      </Button>
    </div>
  )
}
