import { apiRequest } from "./Api"
import { User } from "./User"

interface PageTranslation {
  id: number
  title: string
  locale: string
  content: string
  page_id: number
}
export interface PageRow {
  id: number
  translations: PageTranslation[]
}
export interface PagesResponse {
  total_data: number
  rows: PageRow[]
}

interface CreatePageTranslationInput {
  page_id: number
  content: string
  locale: string
}

export interface PageByIdResponse {
  page: {
    id: number
    translations: PageTranslation[]
    user: User
    user_id: number
  }
}

export const getAllPages = async (locale: string) =>
  apiRequest<string, PagesResponse>(
    "get",
    "api/pages?limit=3&page=1&relations=translations",
    undefined,
    { locale }
  )

export const createPageTranslation = async (data: CreatePageTranslationInput) =>
  apiRequest<CreatePageTranslationInput, any>(
    "put",
    "api/page-translations",
    data
  )
export const getPageById = async (pageId: number, locale: string) =>
  apiRequest<number, PageByIdResponse>(
    "get",
    `api/pages/${pageId}?relations=translations,user`,
    undefined,
    { locale }
  )
