import React from "react"
import { useQueryParameters } from "../../../../lib/hooks/useQueryParameters"
import { HandleErrorState } from "../../HandleErrorState/HandleErrorState"
import { ActiveShape } from "./ActiveShape/ActiveShape"
import { useOpacity } from "../../../../lib/hooks/useOpacity"
import { useRouter } from "../../../../lib/hooks/useRouter"
import { Tooltip } from "../components/Tooltip/Tooltip"
import { Spinner } from "../../Spinner/Spinner"

import {
  PieChart as RePieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip as ReTooltip,
  Legend as ReLegend
} from "recharts"

import "./PieChart.scss"
import { Legend } from "../components/Legend/Legend"
import { useWindowWidth } from "../../../../lib/hooks/useWindowWidth"
import { CategoriesLegend } from "../components/Legend/CategoriesLegend"
import { useTranslation } from "react-i18next"
interface PieProps {
  data: { name: string; value: number }[]
  colors: string[]
  outerRadius: number
  id: string
  loading: boolean
  error: any
  allCategories?: any
}

export const PieChart = React.memo(
  ({
    data,
    colors,
    outerRadius,
    id,
    loading,
    error,
    allCategories,
    ...rest
  }: PieProps) => {
    const { activeIndex, handleMouseEnter, handleMouseLeave } = useOpacity()
    const {
      i18n: { language }
    } = useTranslation()
    const { getUrlWithQueryParams } = useQueryParameters()
    const router = useRouter()
    const width = useWindowWidth()
    const handlePieClick = (data: { name: string; value: number }) => {
      if (!data.value) return
      const url = getUrlWithQueryParams(id, data.name)
      const urlArray = url.split("/")
      urlArray.splice(0, 2)
      router.history.push(`/${language}/data${urlArray.join("/")}`)
    }

    if (loading) return <Spinner />

    return (
      <HandleErrorState error={error}>
        <ResponsiveContainer key={id} width="100%" height="100%">
          <RePieChart width={400} height={400}>
            <ReLegend
              content={
                allCategories ? (
                  <CategoriesLegend
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    colors={colors}
                    data={allCategories}
                  />
                ) : (
                  <Legend
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    colors={colors}
                    data={data}
                  />
                )
              }
              payload={allCategories ? allCategories : [...data]}
              layout="vertical"
              verticalAlign="top"
              align="right"
            />
            <Pie
              isAnimationActive={false}
              className="PieChart__pie"
              data={data.sort((a: { name: string }, b: { name: string }) =>
                a.name.localeCompare(b.name)
              )}
              cx="50%"
              cy="50%"
              outerRadius={outerRadius}
              dataKey="value"
              activeShape={(props) => <ActiveShape {...props} />}
              activeIndex={activeIndex}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handlePieClick}
            >
              {data.map((entry, index) => {
                return (
                  <Cell
                    key={`Cell-${index}`}
                    fill={colors[index % colors.length]}
                    stroke={colors[index % colors.length]}
                    strokeWidth={0.1}
                  />
                )
              })}
              )
            </Pie>
            {width < 1200 && <ReTooltip content={<Tooltip showLabel />} />}
          </RePieChart>
        </ResponsiveContainer>
      </HandleErrorState>
    )
  }
)
