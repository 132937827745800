import React from "react"
import { useTranslation } from "react-i18next"
import { NavLink } from "react-router-dom"
import "./ThemeNav.scss"

interface Props {
  closeMobileMenu: () => void
  page: string
  to: string
}
export const ThemeNav = ({ closeMobileMenu, page, to }: Props) => {
  const { t } = useTranslation()
  return (
    <li
      className="ThemeNav__option"
      onClick={() => closeMobileMenu()}
      key={page}
    >
      <NavLink
        className="ThemeNav__nav_link"
        to={to}
        activeClassName="ThemeNav__nav_active"
        exact
      >
        {t(`${page}`)}
      </NavLink>
    </li>
  )
}
