import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface ReportsFields {
  date?: Date
  name: string
  file: File[]
}

interface UseReportsFormOptions {
  initialValues?: ReportsFields
  onSubmit: (
    values: ReportsFields,
    formikHelpers: FormikHelpers<ReportsFields>
  ) => Promise<any>
}

export const useReportsFormik = (options: UseReportsFormOptions) => {
  const ReportsSchema = Yup.object().shape({
    date: Yup.string().required("Date is a required field!"),
    name: Yup.string().required("Name is a required field!"),
    file: Yup.string().required("File is a required field!")
  })

  return useFormik<ReportsFields>({
    initialValues: {
      date: undefined,
      name: "",
      file: []
    },
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ReportsSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type ReportsFormik = ReturnType<typeof useReportsFormik>
