import React from "react"
import { Modal, ModalProps } from "../../shared/Modal/Modal"
import { ModalBody, ModalHeader } from "reactstrap"
import { EditCase } from "../EditCase/EditCase"
import { useTranslation } from "react-i18next"
import { AddCase } from "../AddCase/AddCase"

import "./CasesDashboardModal.scss"

interface Props extends ModalProps {
  locale: string
  caseId?: number
  onEditOrAddCase: () => void
}

export const CasesModal = ({
  caseId,
  isOpen,
  locale,
  onEditOrAddCase,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal className="CasesDashboardModal" isOpen={isOpen} centered {...rest}>
      <ModalHeader className="CasesDashboardModal__header">
        {caseId ? t("Edit case") : t("Add case")}
      </ModalHeader>
      <ModalBody className="CasesDashboardModal__body">
        {caseId ? (
          <EditCase
            onEditCase={onEditOrAddCase}
            caseId={caseId}
            locale={locale}
            {...rest}
          />
        ) : (
          <AddCase onAddCase={onEditOrAddCase} locale={locale} {...rest} />
        )}
      </ModalBody>
    </Modal>
  )
}
