import { fillValueWithData } from "./fillValueWithData"
import { transformDataForSelect } from "./transformDataForSelect"

export const transformQueryParamsToValue = (
  data: any,
  identifier: any,
  params: string[]
) => {
  if (!data) return

  let value: any[] = []
  params.forEach((param) => {
    data.rows.forEach((row: any) => {
      if (!row) return

      if (!row.children || row?.children?.length < 0) {
        if (row.translations[0].name === param) {
          const data = transformDataForSelect(row, identifier)
          fillValueWithData(value, data)
        }
      } else if (row.translations[0].name !== param) {
        const res = row?.children?.filter((child: any) => {
          return child?.translations[0]?.name === param
        })
        const data = transformDataForSelect(res, identifier)
        fillValueWithData(value, data)
      } else if (row.translations[0].name === param) {
        const data = transformDataForSelect(row.children, identifier)
        fillValueWithData(value, data)
      }
    })
  })
  return value
}
