import React from "react"
import {
  BarChart as ReBarChart,
  CartesianGrid,
  XAxis,
  Bar,
  YAxis,
  Tooltip as ReTooltip,
  ResponsiveContainer,
  Legend as ReLegend,
  Cell
} from "recharts"
import { useOpacity } from "../../../../lib/hooks/useOpacity"
import { useQueryParameters } from "../../../../lib/hooks/useQueryParameters"
import { useRouter } from "../../../../lib/hooks/useRouter"
import { HandleErrorState } from "../../HandleErrorState/HandleErrorState"
import { Legend } from "../components/Legend/Legend"
import { ChartPlaceholder } from "../components/ChartPlaceholder/ChartPlaceholder"
import { Tooltip, Payload } from "../components/Tooltip/Tooltip"

import "./BarChart.scss"
import { useWindowWidth } from "../../../../lib/hooks/useWindowWidth"
import { useTranslation } from "react-i18next"

export interface Data {
  name: string
  value: number
}
interface BarProps {
  data: Data[]
  colors: string[]
  id: string
  loading: boolean
  error: any
}

export const BarChart = ({
  data,
  error,
  colors,
  id,
  loading,
  ...rest
}: BarProps) => {
  const { activeIndex, handleMouseEnter, handleMouseLeave } = useOpacity()
  const { getUrlWithQueryParams } = useQueryParameters()
  const {
    i18n: { language }
  } = useTranslation()
  const router = useRouter()
  const width = useWindowWidth()
  const handleClick = (data: any) => {
    if (!data.value) return
    const url = getUrlWithQueryParams(id, data.name)
    const urlArray = url.split("/")
    urlArray.splice(0, 2)
    router.history.push(`${language}/data${urlArray.join("/")}`)
  }

  if (loading) return <ChartPlaceholder />

  return (
    <HandleErrorState error={error}>
      <ResponsiveContainer key={id} width="100%" height="100%">
        <ReBarChart
          className="BarChart"
          data={data}
          margin={{ bottom: 40 }}
          barCategoryGap={15}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="name"
            stroke="white"
            angle={width > 1450 ? 0 : -10}
            textAnchor="middle"
            dy={10}
            interval={0}
          />
          {/* <XAxis
            dataKey="name"
            textAnchor="start"
            // sclaeToFit="true"
            // verticalAnchor="start"
            interval={0}
            angle={-10}
            stroke="#8884d8"
          /> */}
          <YAxis tickLine={false} axisLine={false} stroke="white" />
          <ReTooltip content={<Tooltip />} cursor={false} />
          <ReLegend
            content={
              <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
                data={data}
                colors={colors}
              />
            }
            payload={[...data] as Payload[]}
            layout="vertical"
            verticalAlign="top"
            align="right"
          />

          <Bar
            dataKey="value"
            fill="#3A856D"
            radius={[5, 5, 0, 0]}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            style={{ cursor: "pointer" }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`bar-${index}`}
                fill={colors[index]}
                fillOpacity={index === activeIndex ? 0.5 : 1}
              />
            ))}
          </Bar>
        </ReBarChart>
      </ResponsiveContainer>
    </HandleErrorState>
  )
}
