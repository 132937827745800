import React, { useEffect } from "react"

import { formatOptions } from "../../lib/helpers/formatOptions"
import { CasesFormik } from "../../lib/hooks/formik/useCasesFormik"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useQueryParameters } from "../../lib/hooks/useQueryParameters"
import { useRouter } from "../../lib/hooks/useRouter"
import { Option, Select } from "../shared/Select/Select"
import qs from "query-string"
import { transformQueryParamsToValue } from "../../lib/helpers/transformQueryParamsToValue"
import { useTranslation } from "react-i18next"
import * as API from "../../api/Api"
interface Props {
  apiRequest: (
    locale: string
  ) => Promise<
    | API.CategoryResponse
    | API.AffectedPartiesResponse
    | API.AttackerResponse
    | API.MeansOfAttackResponse
    | API.AllCountriesResponse
  >
  formik?: CasesFormik
  selectId: string
  name: string
  placeholder: string
  identifier?: string
  className: string
  value?: Option[] | Option | null
  onBlur?: (e: React.FocusEvent<any>) => void
  onChange?: (option: any) => void
  locale?: string
  touched?: boolean
  error?: any
  isMulti?: boolean
}

export const FiltersSelect = ({
  apiRequest,
  selectId,
  formik,
  name,
  identifier,
  placeholder,
  onBlur,
  locale,
  isMulti,
  onChange,
  value,
  error,
  ...rest
}: Props) => {
  const { i18n } = useTranslation()
  const { getArray, getUrlWithQueryParams } = useQueryParameters()
  const { loading, data, error: hasError } = useApiCall(apiRequest, [
    locale ? locale : i18n.language
  ])

  const router = useRouter()
  // get query params
  const queryParams = qs.parse(router.location.search)

  const options = formatOptions(identifier, data?.rows)

  //set initialValues
  useEffect(() => {
    if (!queryParams) return
    Object.keys(queryParams).forEach((key) => {
      if (key === selectId) {
        const params = getArray(key)
        const value = transformQueryParamsToValue(data, identifier, params)
        formik && formik.setFieldValue(selectId, value)
        formik && formik.submitForm()
      }
    })
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  let urlParams: string[] = []

  const handleChange = (option: any) => {
    onChange && onChange(option)
    if (onChange) return
    if (option.length <= 0) {
      const url = getUrlWithQueryParams(selectId, urlParams)
      router.history.push(url)
    }
    option.forEach((item: any) => {
      if (!item) return
      urlParams.push(item.label)
      const url = getUrlWithQueryParams(selectId, urlParams)
      router.history.push(url)
    })
    formik && formik.setFieldValue(selectId, option)
  }
  return (
    <Select
      id={selectId}
      name={name}
      placeholder={placeholder}
      isMulti={isMulti ?? true}
      options={options}
      value={value}
      onChange={handleChange}
      error={error || hasError}
      loading={loading}
      {...rest}
    />
  )
}
