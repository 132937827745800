import React from "react"

import { Table, TableColumn, TableRow } from "../shared/Table/Table"
import { HandleErrorState } from "../shared/HandleErrorState/HandleErrorState"
import { ReportsResponse } from "../../api/Api"
import { TablePagination } from "../shared/TablePagination/TablePagination"
import { TableWrapper } from "../shared/TableWrapper/TableWrapper"

import "./ReportsTable.scss"

interface Props {
  reports?: ReportsResponse
  onPageChange: (currentPage: number) => void
  error: string
  loading: boolean
  columns: TableColumn[]
  rows?: TableRow[]
  currentPage: number
}

export const ReportsTable = ({
  onPageChange,
  loading,
  reports,
  rows,
  currentPage,
  columns,
  error
}: Props) => {
  return (
    <div className="ReportsTable">
      <HandleErrorState error={error}>
        <TableWrapper>
          <Table columns={columns} rows={rows} loading={loading} />
        </TableWrapper>
      </HandleErrorState>

      <TablePagination
        page={currentPage}
        onPageChange={onPageChange}
        pages={reports?.total_data}
      />
    </div>
  )
}
