import React, { useState } from "react"
import { useSkeleton } from "../../../lib/hooks/useSkeleton"
import cs from "classnames"

import "./Table.scss"
import { NoDataFound } from "../NoDataFound/NoDataFound"
import { useTranslation } from "react-i18next"

export interface TableColumn {
  key: string
  header: string
  width?: number
}

export interface TableRow {
  [key: string]: any
}

interface Props {
  columns: TableColumn[]
  rows?: TableRow[]
  loading?: boolean
  emptyPlaceholder?: string
  className?: string;
  totalData?: number;
}

export const Table = (props: Props) => {
  const skeleton = useSkeleton(props.loading)
  const [showMore, setShowMore] = useState<number | null>(null)

  const {t} = useTranslation();

  if (props.rows?.length === 0 && !props.loading)
    return (
      <div className="Table__empty">
        {props.emptyPlaceholder || <NoDataFound />}
      </div>
    )

  return (
    <div className={cs("Table", props.className)}>
      <table>
        <thead>
          <tr>
            {props.columns?.map((col, index) => (
              <th
                key={`Table-${index}-${col.key}`}
                style={{ width: `${col.width}px`, minWidth: "125px" }}
              >
                {skeleton(col.header, {
                  width: "50px",
                  height: "14px",
                  className: "Table__placeholder_header"
                })}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.loading ? (
            <tr className="Table__placeholder">
              <td style={{ width: "1100px" }}>
                {skeleton("", {
                  height: "18px",
                  width: "411px",
                  count: 10,
                  className: "Table__placeholder_row"
                })}
              </td>
              <td>
                {skeleton("", {
                  height: "18px",
                  width: "96px",
                  count: 10,
                  className: "Table__placeholder_row"
                })}
              </td>
              <td>
                {skeleton("", {
                  height: "18px",
                  width: "96px",
                  count: 10,
                  className: "Table__placeholder_row"
                })}
              </td>
            </tr>
          ) : (
            props.rows?.map((row: TableRow, index: number) => {
              return (
                <tr key={`${row["key"]} -- ${index}`}>
                  {props.columns.map((rowColumn, idx) => (
                    <td key={idx}>
                      {rowColumn.key === "category" ? (
                        <CategoryColumn
                          row={row["category"]}
                          showMore={showMore}
                          index={index}
                        />
                      ) : (
                        row[rowColumn["key"]]
                      )}

                      {rowColumn.key === "category" &&
                        row["category"].length > 200 && (
                          <span
                            onClick={() =>
                              setShowMore((prevState) => {
                                if (prevState === index) return null
                                return index
                              })
                            }
                          >
                            {showMore === index ? "Show less" : "Show more"}
                          </span>
                        )}
                    </td>
                  ))}
                </tr>
              )
            })
          )}
        </tbody>
      </table>
      <div className="table-total border-top">
        {t('Total Cases')}: {props.totalData}
      </div>
    </div>
  )
}

export const CategoryColumn = (props: {
  row: TableRow
  showMore: number | null
  index: number
}) => {
  const { row, index, showMore } = props
  const shortenCategory =
    row?.length > 200 ? row?.substring(0, 200) + "..." : row
  return <div>{showMore === index ? row : shortenCategory}</div>
}
