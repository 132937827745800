import React from "react"
import { Sector } from "recharts"
import { calculateActiveShapeCoordinates } from "../../../../../lib/helpers/calculateActiveShapeCoordinates"
import cs from "classnames"

import "./ActiveShape.scss"
import { useWindowWidth } from "../../../../../lib/hooks/useWindowWidth"

export interface Payload {
  payload: { name: string; value: number }
}
interface ActiveShapeProps {
  cx: number
  cy: number
  innerRadius: number
  outerRadius: number
  startAngle: number
  endAngle: number
  fill: string
  name: string
  stroke: string
  midAngle: number
  payload: Payload
}
export const ActiveShape = (props: ActiveShapeProps) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    midAngle,
    payload,
    name
  } = props

  const width = useWindowWidth()
  const {
    sx,
    sy,
    ex,
    ey,
    textAnchor,
    cos,
    mx,
    my
  } = calculateActiveShapeCoordinates(cx, cy, midAngle, outerRadius)

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill="#FFF"
      />

      {payload.payload.value >= 1 ? (
        width > 1200 || outerRadius < 100 ? (
          <>
            <p> {payload.payload.value}</p>

            <circle cx={ex} cy={ey} r={2} fill="#FFF" stroke="none" />
            <path
              d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
              stroke="white"
              fill="none"
            />
            <foreignObject
              width="150px"
              height="12px"
              x={ex + (cos >= 0 ? 1 : -1) * -10}
              y={ey - 20}
            >
              <p
                style={{ fontSize: "12px", color: "#FFF" }}
                className={cs({ "faux-tspan": textAnchor === "middle" })}
              >
                {payload.payload.value}
              </p>
            </foreignObject>
            <foreignObject
              width="150px"
              height="12px"
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey - 7}
            >
              <p
                style={{ fontSize: "12px", color: "#FFF" }}
                className={cs({ "faux-tspan": textAnchor === "end" })}
              >
                {name}
              </p>
            </foreignObject>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}
    </g>
  )
}
