import React from "react"
import { useTranslation } from "react-i18next"
import { Route, RouteProps, useHistory } from "react-router-dom"
import { useAuthContext } from "../lib/context/AuthContext/AuthContext"

export const RestrictedRoute = (props: RouteProps) => {
  const authCtx = useAuthContext()
  const {
    i18n: { language }
  } = useTranslation()
  const history = useHistory()

  if (authCtx.isAuthenticated) {
    history.push(`/${language}/`)
  }
  return <Route {...props} />
}
