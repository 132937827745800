import React, { useEffect } from "react"
import { LanguageContext } from "./LanguageContext"
import { useApiCall } from "../../hooks/useApiCall"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { LoadingScreen } from "../../../components/shared/LoadingScreen/LoadingScreen"
import { HandleErrorState } from "../../../components/shared/HandleErrorState/HandleErrorState"
import i18n from "i18next"
import { formatTranslations } from "../../helpers/formatTranslations"
import Axios from "axios"

import * as API from "../../../api/Api"
interface LanguageContextProviderProps {
  children: React.ReactNode | null
}
const url = process.env.REACT_APP_API_URL

const getLanguage = () => {
  if (url?.includes("xk")) return "sq"
  if (url?.includes("al")) return "sq"
  if (url?.includes("me")) return "mg"
  return "en"
}

export const LanguageContextProvider = (
  props: LanguageContextProviderProps
) => {
  const { loading, data: labels, error } = useApiCall(API.getAllLabels)
  const translations = formatTranslations(labels)

  useEffect(() => {
    i18n.use(LanguageDetector).use(initReactI18next).init({
      debug: false,
      lng: getLanguage(),
      fallbackLng: "en",
      resources: translations
    })
  }, [translations])

  const changeLanguage = (lang: string) => {
    localStorage.setItem("locale", lang)
    i18n.changeLanguage(lang)
    Axios.defaults.headers.common["locale"] = lang
  }

  if (loading) return <LoadingScreen />
  return (
    <HandleErrorState error={error}>
      <LanguageContext.Provider
        value={{
          loading,
          changeLanguage
        }}
      >
        {props.children}
      </LanguageContext.Provider>
    </HandleErrorState>
  )
}
