import React, { CSSProperties, ReactNode } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

interface SkeletonOptions {
  count?: number
  duration?: number
  delay?: number
  width?: string | number
  wrapper?: ReactNode
  height?: string | number
  circle?: boolean
  style?: CSSProperties
  className?: string
  color?: string
}

export const useSkeleton = (
  loading?: boolean,
  defaultOptions?: SkeletonOptions
) => (content?: ReactNode, options?: SkeletonOptions) => {
  if (loading)
    return (
      <SkeletonTheme color="#675656" highlightColor="#1C1616">
        <Skeleton {...defaultOptions} {...options} />
      </SkeletonTheme>
    )

  return content
}
