import React, { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getNumberOfPages } from "../../../lib/helpers/getNumberOfPages"
import { useWindowWidth } from "../../../lib/hooks/useWindowWidth"
import { Icon } from "../Icon/Icon"

import "./TablePagination.scss"

interface Props {
  pages: any
  page: any
  onPageChange: (page: any) => void
}

export const TablePagination = (props: Props) => {
  const width = useWindowWidth()
  const { t } = useTranslation()
  const pages = getNumberOfPages(10, props.pages)

  const filterPages = (visiblePages: any[], totalPages: any) => {
    return visiblePages.filter((page) => page <= totalPages)
  }

  const getVisiblePages = useCallback((page: any, total: any) => {
    if (total < 7) {
      return filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }, [])

  const [visiblePages, setVisiblePages] = useState(getVisiblePages(null, pages))

  useEffect(() => {
    setVisiblePages(getVisiblePages(null, pages))
  }, [getVisiblePages, pages])
  if (!props.page || !props.pages) return null

  const changePage = (page: any) => {
    const activePage = props.page

    if (page === activePage) {
      return
    }

    const visiblePages = getVisiblePages(page, pages)
    setVisiblePages(filterPages(visiblePages, pages))

    props.onPageChange(page)
  }

  const activePage = props.page
  return (
    <div className="TablePagination">
      <button
        className="TablePagination__prevPage"
        onClick={() => {
          if (activePage === 1) return
          changePage(activePage - 1)
        }}
        disabled={activePage === 1}
      >
        {" "}
        <Icon icon="back-arrow" className="TablePagination__back" />
        {width < 767 ? "" : t("newer entries")}
      </button>
      <div className="TablePagination__visiblePagesWrapper">
        {visiblePages.map((page: any, index: any, array: any) => {
          return (
            <button
              key={page}
              className={
                activePage === page
                  ? "TablePagination__pageButton TablePagination__pageButton_active"
                  : "TablePagination__pageButton"
              }
              onClick={() => changePage(page)}
            >
              {array[index - 1] + 2 < page ? `...${page}` : page}
            </button>
          )
        })}
      </div>
      <button
        className="TablePagination__nextPage"
        onClick={() => {
          if (activePage === pages) return
          changePage(activePage + 1)
        }}
        disabled={activePage === pages}
      >
        {width < 767 ? "" : t("older entries")}
        <Icon icon="next-arrow" className="TablePagination__next" />
      </button>
    </div>
  )
}
