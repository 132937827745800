import React from "react"
import { useTranslation } from "react-i18next"
import { LanguagesRows } from "../../../../api/Language"
// import { useConfirmation } from "../../../../lib/context/ConfirmationContext/ConfirmationContext"

import { TableButton } from "../../../shared/Table/TableButton/TableButton"

export const useLanguageRows = (
  onEditClick: (langId: number) => void,
  // handleDeleteLanguage: (locale: string, langId: number) => void,
  toggle: () => void,
  // isDeleting: boolean,
  langId?: number,
  rows?: LanguagesRows[]
) => {
  // const confirmation = useConfirmation()
  const { t } = useTranslation()
  return rows?.map((row) => {
    return {
      language: row?.language,
      locale: row?.locale,
      edit: (
        <TableButton
          icon="edit"
          onClick={() => {
            toggle()
            onEditClick(row.id)
          }}
        >
          {t("Edit")}
        </TableButton>
      )
      // delete: (
      //   <TableButton
      //     onClick={() =>
      //       confirmation
      //         .confirm(t("Delete"))
      //         .then(() => handleDeleteLanguage(row.locale, row.id))
      //     }
      //   >
      //     {isDeleting && row.id === langId ? t("deleting") : t("Delete")}
      //   </TableButton>
      // )
    }
  })
}
