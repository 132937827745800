import React from "react"

import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState"
import { HandleErrorState } from "../../components/shared/HandleErrorState/HandleErrorState"
import { TextPlaceholder } from "../../components/shared/TextPlaceholder/TextPlaceholder"
import { useTranslation } from "react-i18next"
import { useApiCall } from "../../lib/hooks/useApiCall"

import * as API from "../../api/Api"

import "./About.scss"

export const About = () => {
  const { i18n } = useTranslation()
  const { loading, data: pageById, error } = useApiCall(API.getPageById, [
    3,
    i18n.language
  ])

  const aboutContent = pageById?.page?.translations?.[0].content

  return (
    <div className="About">
      <HandleLoadingState
        loading={loading}
        placeholder={<TextPlaceholder width={1100} count={50} />}
      >
        <HandleErrorState error={error}>
          <div dangerouslySetInnerHTML={{ __html: aboutContent || "" }} />
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
