import React, { useContext } from "react"

const defaultContext = {
  shouldRefresh: false,
  setShouldRefresh: (value: boolean) => {}
}
export interface FiltersContextType {
  shouldRefresh: boolean
  setShouldRefresh: (value: boolean) => void
}

export const FiltersContext = React.createContext<FiltersContextType>(
  defaultContext
)

export function useFiltersContext() {
  return useContext(FiltersContext)
}
