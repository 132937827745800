import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface LanguageFields {
  language: string
  locale: string
}

interface UseLanguageFormOptions {
  initialValues?: LanguageFields
  onSubmit: (
    values: LanguageFields,
    formikHelpers: FormikHelpers<LanguageFields>
  ) => Promise<any>
}

export const useLanguageFormik = (options: UseLanguageFormOptions) => {
  const LanguageSchema = Yup.object().shape({
    language: Yup.string().required("Language is a required field!"),
    locale: Yup.string().required("Locale is a required field!")
  })

  return useFormik<LanguageFields>({
    initialValues: {
      language: "",
      locale: "",
      ...options.initialValues
    },
    isInitialValid: false,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LanguageSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type LanguageFormik = ReturnType<typeof useLanguageFormik>
