import React, { useEffect, useState } from "react"
import { EditorState, convertToRaw } from "draft-js"
import { HandleErrorState } from "../../components/shared/HandleErrorState/HandleErrorState"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { LocaleSelector } from "../../components/shared/LocaleSelector/LocaleSelector"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { Editor } from "../../components/shared/Editor/Editor"
import { useTranslation } from "react-i18next"
import { NoDataFound } from "../../components/shared/NoDataFound/NoDataFound"
import { Spinner } from "../../components/shared/Spinner/Spinner"

import draftToHtml from "draftjs-to-html"
import * as API from "../../api/Api"

import "./GeneralSettings.scss"

export const GeneralSettings = () => {
  const { i18n } = useTranslation()
  const [pageId, setPageId] = useState<number>()
  const [locale, setLocale] = useState<string>(i18n.language)

  const { loading, data: pages, error } = useApiCall(API.getAllPages, [locale])
  const errorHandler = useErrorHandler()

  useEffect(() => {
    setLocale(i18n.language)
  }, [i18n.language])

  const handleSubmit = async (pageId: number, editorState: EditorState) => {
    try {
      setPageId(pageId)
      await API.createPageTranslation({
        page_id: pageId,
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        locale
      })
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setPageId(undefined)
    }
  }

  const handleLocaleChange = (locale: string) => {
    setLocale(locale)
  }

  if (loading)
    return (
      <div className="GeneralSettings__loading">
        <Spinner />
        <Spinner />
        <Spinner />
      </div>
    )

  if (!pages?.total_data) return <NoDataFound />

  return (
    <div className="GeneralSettings">
      <LocaleSelector language={locale} onLocaleChange={handleLocaleChange} />
      <HandleErrorState error={errorHandler.error || error}>
        {pages?.rows.map((page) => {
          return (
            <Editor
              key={page.id}
              id={page.id}
              page={page}
              title={page.translations[0].title}
              placeholder={page.translations[0].title}
              onSubmit={handleSubmit}
              isSubmitting={pageId === page.id}
            />
          )
        })}
      </HandleErrorState>
    </div>
  )
}
