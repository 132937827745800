import React from "react"
import { LabelsFormik } from "../../../lib/hooks/formik/useLablesFormik"
import { UseErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { Input } from "../../shared/Input/Input"
import { Option, Select } from "../../shared/Select/Select"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { FormButtons } from "../../shared/FormButtons/FormButtons"

import * as API from "../../../api/Api"

import "./LabelForm.scss"
import { useTranslation } from "react-i18next"

interface Props {
  formik: LabelsFormik
  labelLoading?: boolean
  labelError?: string
  errorHandler?: UseErrorHandler
  onLocaleChange?: (locale: Option) => void
  toggle?: () => void
  localeKey?: string
}

export const LabelForm = ({
  formik,
  errorHandler,
  onLocaleChange,
  labelLoading,
  toggle,
  localeKey,
  labelError
}: Props) => {
  const {
    loading: languagesLoading,
    data: languages,
    error: languagesError
  } = useApiCall(API.getLanguages)

  const handleChange = (option: Option[] | Option) => {
    onLocaleChange && onLocaleChange(option as Option)
    formik.setFieldValue("locale", option)
  }
  const { t } = useTranslation()

  return (
    <div className="LabelsForm">
      <form className="LabelsForm__form" onSubmit={formik.handleSubmit}>
        <Input
          className="LabelsForm__input"
          loading={labelLoading}
          type="text"
          name="key"
          id="key"
          placeholder={t("Key")}
          value={formik.values.key}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.key}
          error={formik.errors.key || labelError}
          disabled={!!localeKey}
        />

        <Select
          className="LabelsForm__select"
          loading={languagesLoading}
          isClearable
          error={formik.errors.locale || languagesError}
          touched={formik.touched.locale}
          isMulti={false}
          placeholder={t("Locale")}
          name="locale"
          id="locale"
          options={languages?.rows.map((lang) => ({
            label: lang.locale,
            value: lang.locale
          }))}
          value={formik.values.locale}
          onChange={(option) => {
            formik.setFieldValue("locale", option)
            formik.setFieldTouched("locale")
          }}
          onBlur={formik.handleBlur}
          closeMenuOnSelect
        />

        <Input
          className="LabelsForm__input"
          loading={labelLoading}
          type="text"
          name="value"
          id="value"
          placeholder={t("Value")}
          value={formik.values.value}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.value}
          error={formik.errors.value || labelError}
        />
        <FormButtons
          toggle={toggle}
          disabled={!formik.isValid || formik.isSubmitting}
          isSubmitting={formik.isSubmitting}
        />

        <ErrorMessage errorData={errorHandler} onClose={errorHandler?.reset} />
      </form>
    </div>
  )
}
