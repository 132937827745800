import React from "react"

//Partials
import { Container } from "./components/shared/Container/Container"
import { Header } from "./partials/Header/Header"
import { Routes } from "./routes/Routes"
import { Footer } from "./partials/Footer/Footer"
import { useAuthContext } from "./lib/context/AuthContext/AuthContext"
import { LoadingScreen } from "./components/shared/LoadingScreen/LoadingScreen"

//Styles
import "./App.scss"

function App() {
  const authCtx = useAuthContext()

  if (authCtx.isLoading) return <LoadingScreen />

  return (
    <div className="App">
      <Container>
        <Header />
        <Routes />
        <Footer />
      </Container>
    </div>
  )
}

export default App
