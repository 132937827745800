/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"

import { FiltersSelect } from "../FiltersSelect/FiltersSelect"
import { TableButton } from "../shared/Table/TableButton/TableButton"
import { CasesFormik } from "../../lib/hooks/formik/useCasesFormik"
import { DatePicker } from "../DatePicker/DatePicker"
import { useRouter } from "../../lib/hooks/useRouter"
import { Collapse } from "../shared/Collapse/Collapse"
import { Button } from "../shared/Button/Button"
import { Input } from "../shared/Input/Input"

import * as API from "../../api/Api"

import "./Filters.scss"
import { FiltersState } from "../../pages/Cases/Cases"
import { useTranslation } from "react-i18next"
import { useFiltersContext } from "../../lib/context/FiltersContext/FiltersContext"

interface FiltersProps {
  formik: CasesFormik
  onInputChange?: (e: any) => void
  onSelectChange?: (id: any, option: any) => void
  values?: FiltersState
}

export const Filters = ({ formik, values }: FiltersProps) => {
  const router = useRouter()
  const {
    t,
    i18n: { language }
  } = useTranslation()

  const { shouldRefresh } = useFiltersContext()

  /**
   * The state of shouldRefresh is changed when NavLink "DATA" is clicked, it sets the new value to the inverse of the initial value
   * Everytime when the DATA Navlink is clicked, it triggers this use effect
   */
  useEffect(() => {
    formik.submitForm()
    formik.resetForm()
    formik.setFieldValue("title", "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh])
  return (
    <div className="Filters">
      <Collapse title={t("Filters")}>
        <form className="Filters__form" onSubmit={formik.handleSubmit}>
          <Input
            className="Filters__input"
            type="text"
            name="title"
            placeholder={t("Search")}
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.title}
            icon="search"
          />
          <FiltersSelect
            identifier="affected_party_id"
            className="Filters__select"
            name="affectedParties"
            selectId="affectedParties"
            placeholder={t("Affected parties")}
            apiRequest={API.getAllAffectedParties}
            formik={formik}
            value={formik.values.affectedParties}
          />

          <FiltersSelect
            identifier="attacker_id"
            className="Filters__select"
            name="attackers"
            selectId="attackers"
            placeholder={t("Attackers")}
            apiRequest={API.getAllAttackers}
            formik={formik}
            value={formik.values.attackers}
          />
          <FiltersSelect
            identifier="attack_means_id"
            className="Filters__select"
            placeholder={t("Means of attack")}
            name="meansOfAttacks"
            selectId="meansOfAttacks"
            apiRequest={API.getMeansOfAttacks}
            formik={formik}
            value={formik.values.meansOfAttacks}
          />
          <FiltersSelect
            identifier="category_id"
            className="Filters__select"
            apiRequest={API.getAllCategories}
            formik={formik}
            placeholder={t("Category")}
            name="categories"
            selectId="categories"
            value={formik.values.categories}
          />
          {/* <FiltersSelect
            identifier="country_id"
            className="Filters__select"
            apiRequest={API.getAllCountries}
            formik={formik}
            // isMulti={false}
            placeholder={t("Country")}
            name="country_id"
            selectId="country_id"
            value={formik.values.country_id}
          /> */}

          <DatePicker
            className="Filters__select"
            placeholder={t("Select date")}
            onChange={(dates: [Date, Date]) => {
              formik.setFieldValue("dates", dates)
            }}
            startDate={formik.values.dates?.[0]}
            endDate={formik.values.dates?.[1]}
            error={formik.errors.dates}
            selectsRange
          />
          <div className="Filters__buttons">
            <Button
              type="submit"
              name="search"
              className="Filters__submit"
              disabled={formik.isSubmitting || !formik.dirty}
            >
              {formik.isSubmitting ? t("submitting") : t("Search")}
            </Button>
            <TableButton
              type="reset"
              name="clear"
              className="Filters__clear"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.submitForm()
                formik.resetForm()
                router.history.push(`/${language}/data`)
              }}
            >
              {t("Clear")}
            </TableButton>
          </div>
        </form>
      </Collapse>
    </div>
  )
}
