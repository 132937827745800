import React, { useContext } from "react"

const defaultContext = {
  loading: true,
  changeLanguage: (language: string) => {},
  getLngPrefix: () => ""
}

export interface LanguageContextType {
  loading: boolean
  getLngPrefix?: () => string
  changeLanguage: (language: string) => void
}

export const LanguageContext = React.createContext<LanguageContextType>(
  defaultContext
)

export const useLanguageContext = () => useContext(LanguageContext)
