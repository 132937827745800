export const getChartColors = (colorNumber: number) => {
  const colors = []
  let count = 0
  const colorTotal = 100
  function rand(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }
  while (count < colorTotal) {
    colors.push(
      "hsl(" + colorNumber + ", " + rand(10, 50) + "%, " + rand(10, 90) + "%)"
    )
    count++
  }
  return colors
}
