import Axios from "axios"
import React, { useState, useEffect } from "react"

import * as API from "../../../api/Api"

import { AuthContextType, AuthContext } from "./AuthContext"

interface AuthContextProviderProps {
  children: React.ReactNode | null
}

const LOCAL_STORAGE_KEY = "BIRN_MONITOR_SHARING"

export const AuthContextProvider = (props: AuthContextProviderProps) => {
  const [user, setUser] = useState<API.User | undefined>(undefined)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<
    string | { [key: string]: string } | undefined
  >(undefined)

  useEffect(() => {
    checkAuthentication()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const checkAuthentication = async () => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (!accessToken) {
      setLoading(false)
      return
    }

    try {
      Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
      const res = await API.getUserDetails()
      setUser(res.user)
    } catch (err) {
      setError(err.toString())
    } finally {
      setLoading(false)
    }
  }

  const login = (credentials: API.LoginRegisterResponse) => {
    Axios.defaults.headers.common.Authorization = `Bearer ${credentials.access_token}`
    setUser(credentials.user)
    localStorage.setItem(LOCAL_STORAGE_KEY, credentials.access_token)
  }

  const logout = () => {
    setUser(undefined)
    localStorage.removeItem(LOCAL_STORAGE_KEY)
  }

  const context: AuthContextType = {
    isAuthenticated: user !== undefined,
    isLoading: loading,
    error: error,
    user: user,
    login: login,
    logout: logout,
    checkAuthentication
  }

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  )
}
