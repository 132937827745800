import React from "react"

import { LabelsTable } from "../../components/Label/LabelsTable/LabelsTable"

import "./Labels.scss"

export const Labels = () => {
  return (
    <div className="Labels">
      <LabelsTable />
    </div>
  )
}
