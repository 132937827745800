import React from "react"
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState"
import { HandleErrorState } from "../../components/shared/HandleErrorState/HandleErrorState"
import { TextPlaceholder } from "../../components/shared/TextPlaceholder/TextPlaceholder"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useTranslation } from "react-i18next"

import * as API from "../../api/Api"

import "./Methodology.scss"

export const Methodology = () => {
  const { i18n } = useTranslation()
  const { loading, data: pageById, error } = useApiCall(API.getPageById, [
    2,
    i18n.language
  ])

  const methodologyContent = pageById?.page?.translations?.[0].content

  return (
    <div className="Methodology">
      <HandleLoadingState
        loading={loading}
        placeholder={<TextPlaceholder width={1100} count={50} />}
      >
        <HandleErrorState error={error}>
          <div dangerouslySetInnerHTML={{ __html: methodologyContent || "" }} />
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
