export const transformDataForSelect = (data: any, id: string) => {
  if (!data && data?.length < 0) return
  if (Array.isArray(data)) {
    return data.map((item: any) => ({
      label: item.translations[0].name,
      value: item.translations[0][id].toString()
    }))
  }
  return {
    label: data.translations[0].name,
    value: data.translations[0][id].toString()
  }
}
