import React from "react"
import { ModalBody, ModalHeader } from "reactstrap"
import { Modal, ModalProps } from "../../shared/Modal/Modal"
import { EditLabel } from "../EditLabel/EditLabel"
import { AddLabel } from "../AddLabel/AddLabel"
import { Label } from "../../../api/Labels"

import "./LabelModal.scss"
import { useTranslation } from "react-i18next"

interface Props extends ModalProps {
  localeKey?: string
  onEditLabel?: (label: Label) => void
  onAddLabel: (label: Label) => void
}

export const LabelModal = ({
  localeKey,
  onAddLabel,
  onEditLabel,
  toggle,
  ...rest
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal className="LabelModal" toggle={toggle} {...rest} centered>
      <ModalHeader className="LabelModal__header">
        {localeKey ? t("Edit label") : t("Add label")}
      </ModalHeader>

      <ModalBody className="LabelModal__body">
        {localeKey ? (
          <EditLabel
            localeKey={localeKey}
            onEditLabel={onEditLabel}
            toggle={toggle}
            {...rest}
          />
        ) : (
          <AddLabel toggle={toggle} onAddLabel={onAddLabel} {...rest} />
        )}
      </ModalBody>
    </Modal>
  )
}
