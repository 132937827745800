/* eslint-disable @typescript-eslint/no-unused-vars */
import { CasesWithCountResponse } from "../../api/Cases"

export const formatLineChartData = (data?: CasesWithCountResponse) => {
  const parents = data?.categories.map(
    (category) => category.translations?.[0].name
  )
  const lineChartData: any = []
  const res = data?.categories.forEach((category) => {
    let obj: any = {}

    category.data.forEach((entry) => {
      obj.name = entry.date
      const res = parents?.forEach((parent) => {
        if (category.translations?.[0].name === parent) {
          return (obj[parent] = entry.count)
        }
        obj[parent] = 0
      })
      lineChartData.push(obj)
      obj = {}
    })
  })

  return { lineChartData, parents }
}
