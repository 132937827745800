import React, { useEffect } from "react"
import { useState } from "react"
import { UseErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { Alert } from "../Alert/Alert"
import cs from "classnames"

import "./ErrorMessage.scss"
interface Props {
  errorData?: UseErrorHandler
  onClose?: () => void
  children?: JSX.Element
  duration?: number
  className?: string
}

export const ErrorMessage = (props: Props) => {
  const [visible, setVisible] = useState<boolean>(false)

  useEffect(() => {
    if (!props.errorData?.error) {
      setVisible(false)
      return
    }
    setVisible(true)
    const timer = setTimeout(
      () => {
        setVisible(false)
        props.onClose && props.onClose()
      },
      props.duration ? props.duration * 1000 : 3000
    )
    return () => clearTimeout(timer)
  }, [props])

  if (!visible) return null

  if (!props?.errorData?.error) return null

  return typeof props.errorData.error === "string" ? (
    <Alert
      className={cs("ErrorMessage", props.className)}
      color="danger"
      show={!!props.errorData}
      isOpen={visible}
      toggle={props.onClose}
    >
      {props.errorData.error}
    </Alert>
  ) : (
    <Alert
      className={cs("ErrorMessage", props.className)}
      color="danger"
      show={!!props.errorData}
      isOpen={visible}
      toggle={props.onClose}
    >
      {Object.values(props.errorData.error).map((error: any, i) => {
        return <div key={i}>{error}</div>
      })}
    </Alert>
  )
}
