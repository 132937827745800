import React from "react"
import { useTranslation } from "react-i18next"
import { ModalBody, ModalHeader } from "reactstrap"
import { AddEditLanguageResponse } from "../../../api/Language"
import { Modal, ModalProps } from "../../shared/Modal/Modal"
import { AddLanguage } from "../AddLanguage/AddLanguage"
import { EditLanguage } from "../EditLanguage/EditLanguage"

import "./LanguageModal.scss"

interface Props extends ModalProps {
  langId?: number
  onAddLanguage: (language: AddEditLanguageResponse) => void
  onEditLanguage: (language: AddEditLanguageResponse) => void
}

export const LanguageModal = ({
  isOpen,
  toggle,
  langId,
  onAddLanguage,
  onEditLanguage
}: Props) => {
  const { t } = useTranslation()
  return (
    <Modal className="LanguageModal" isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader className="LanguageModal__header">
        {langId ? t("Edit language") : t("Add language")}
      </ModalHeader>
      <ModalBody className="LanguageModal__body">
        {langId ? (
          <EditLanguage
            langId={langId}
            toggle={toggle}
            onEditLanguage={onEditLanguage}
          />
        ) : (
          <AddLanguage toggle={toggle} onAddLanguage={onAddLanguage} />
        )}
      </ModalBody>
    </Modal>
  )
}
