import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface CasesDashboardFields {
  title?: string
  affectedParties?: { value: string; label: string }[] | null
  attackers?: { value: string; label: string }[] | null
  country_id?: { value: string; label: string } | null
  meansOfAttacks?: { value: string; label: string }[] | null
  categories?: { value: string; label: string }[] | null
  startDate?: Date | null
  endDate?: Date | null
  outcome?: string
  files_to_delete: number[]
  description?: string
  links: { link: string; id: number | null }[]
  files?: any
}

interface UseCasesDashboardFormOptions {
  initialValues?: CasesDashboardFields
  onSubmit: (
    values: CasesDashboardFields,
    formikHelpers: FormikHelpers<CasesDashboardFields>
  ) => Promise<any>
}

export const useCasesDashboardFormik = (
  options: UseCasesDashboardFormOptions
) => {
  const CasesDashboardSchema = Yup.object().shape({
    title: Yup.string().required("Title is a required field!"),
    affectedParties: Yup.string()
      .nullable()
      .required("Affected parties are a required field!"),
    attackers: Yup.string()
      .nullable()
      .required("Attackers are a required field!"),
    // country_id: Yup.string()
    //   .nullable()
    //   .required("Country is a required field!"),

    categories: Yup.string()
      .nullable()
      .required("Categories are a required field!"),
    startDate: Yup.date()
      .nullable()
      .required("Start date is a required field!"),
    endDate: Yup.date()
      .nullable()
      .typeError("Invalid Date")
      .min(Yup.ref("startDate"), "End date can't be before start date"),
    // // outcome: Yup.string().required("Outcome is a required field!"),
    description: Yup.string().required("Description is a required field!"),
    links: Yup.array()
      .of(
        Yup.object().shape({
          link: Yup.string().matches(/^https:\/\/(.*)$/, {
            message: "Please make sure that the link starts with https://",
            excludeEmptyString: true
          })
        })
      )
      .nullable()
  })

  return useFormik<CasesDashboardFields>({
    initialValues: {
      title: undefined,
      affectedParties: null,
      attackers: null,
      meansOfAttacks: null,
      country_id: null,
      categories: null,
      startDate: null,
      endDate: null,
      outcome: "",
      files_to_delete: [],
      description: "",
      links: [{ link: "", id: null }],
      files: [],
      ...options.initialValues
    },
    validateOnMount: true,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: CasesDashboardSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type CasesDashboardFormik = ReturnType<typeof useCasesDashboardFormik>
