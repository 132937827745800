export const formatDataForChart = (name: keyof any, data?: any) => {
  const dataToReturn =
    data &&
    data[name].length > 0 &&
    data[name]
      .filter((item: any) => item.cases_count > 0)
      .map((item: any) => ({
        name: item.translations[0].name,
        value: item.cases_count
      }))

  return dataToReturn && dataToReturn.length !== 0
    ? dataToReturn
    : [{ name: "No data", value: 1 }]
}
