import React from "react"

import "./Container.scss"
interface Props {
  children: JSX.Element[]
}

export const Container = (props: Props) => {
  return <div className="Container">{props.children}</div>
}
