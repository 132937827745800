import React, { useEffect } from "react"
import { RouteProps, Switch, useHistory } from "react-router-dom"

import { PublicRoute } from "./PublicRoute"
import { PrivateRoute } from "./PrivateRoute"
import { Login } from "../pages/Login/Login"
import { Profile } from "../pages/Profile/Profile"
import { Home } from "../pages/Home/Home"
import { Page404 } from "../pages/Page404/Page404"
import { RestrictedRoute } from "./RestrictedRoute"
import { Methodology } from "../pages/Methodology/Methodology"
import { Reports } from "../pages/Reports/Reports"
import { About } from "../pages/About/About"
import { Cases } from "../pages/Cases/Cases"
import { Labels } from "../pages/Labels/Labels"
import { Language } from "../pages/Language/Language"
import { ReportsDashboard } from "../pages/ReportsDashboard/ReportsDashboard"
import { UsersDashboard } from "../pages/UsersDashboard/UsersDashboard"
import { CasesDashboard } from "../pages/CasesDashboard/CasesDashboard"
import { GeneralSettings } from "../pages/GeneralSettings/GeneralSettings"
import { useTranslation } from "react-i18next"
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext"

export enum RouteType {
  PUBLIC,
  PRIVATE,
  RESTRICTED
}
interface AppRoute extends RouteProps {
  type?: RouteType
}
export const AppRoutes: AppRoute[] = [
  // Restricted Routes
  {
    type: RouteType.RESTRICTED,
    exact: true,
    path: ":lang/login",
    component: Login
  },
  // Private Routes
  {
    type: RouteType.PRIVATE,
    path: ":lang/my-profile",
    component: Profile
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: ":lang/admin/labels",
    component: Labels
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: ":lang/admin/languages",
    component: Language
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: ":lang/admin/reports",
    component: ReportsDashboard
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: ":lang/admin/users",
    component: UsersDashboard
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: ":lang/admin/cases",
    component: CasesDashboard
  },
  {
    type: RouteType.PRIVATE,
    exact: true,
    path: ":lang/admin/settings",
    component: GeneralSettings
  },
  // Public Routes
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: ":lang/",
    component: Home
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: ":lang/methodology",
    component: Methodology
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: ":lang/about",
    component: About
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: ":lang/reports",
    component: Reports
  },
  {
    type: RouteType.PUBLIC,
    exact: true,
    path: ":lang/data",
    component: Cases
  }
]

export const Routes = () => {
  const history = useHistory()
  const {
    location: { pathname, search }
  } = history
  const {
    i18n: { language }
  } = useTranslation()

  const supportedLanguages = process.env.REACT_APP_SUPPORTED_LANGUAGES?.split(
    " "
  )

  const { changeLanguage } = useLanguageContext()
  useEffect(() => {
    const urlPath = pathname.split("/")
    if (urlPath.length < 3) {
      const urlLanguage = urlPath[1]
      if (!urlLanguage) {
        //no url language
        history.push(`/${language}/`)
      }
      if (!supportedLanguages?.includes(urlLanguage)) {
        history.push(`/${language}/`)
      }
      if (
        supportedLanguages?.includes(urlLanguage) &&
        urlLanguage === language
      ) {
        if (urlPath.length <= 3) {
          history.push(`/${language}/`)
        }
        //change language
        return
      }
      if (
        supportedLanguages?.includes(urlLanguage) &&
        urlLanguage !== language
      ) {
        changeLanguage(urlLanguage)
      }
    } else {
      const urlLanguage = urlPath.splice(0, 2)[1]
      const urlAfterLanguage = urlPath.join("/")

      if (!supportedLanguages?.includes(urlLanguage)) {
        history.push(`/${language}/${search}`)
      }
      if (
        supportedLanguages?.includes(urlLanguage) &&
        urlLanguage === language
      ) {
        history.push(`/${language}/${urlAfterLanguage}${search}`)
      }
      if (
        supportedLanguages?.includes(urlLanguage) &&
        urlLanguage !== language
      ) {
        changeLanguage(urlLanguage)
        history.push(`/${urlLanguage}/${urlAfterLanguage}${search}`)
      }
    }
  }, [history, language])

  return (
    <Switch>
      {AppRoutes.map((r) => {
        const { type, path, ...rest } = r
        if (type === RouteType.PRIVATE) {
          return (
            <PrivateRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          )
        }
        if (type === RouteType.RESTRICTED) {
          return (
            <RestrictedRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
          )
        }

        return <PublicRoute {...rest} key={`${r.path}`} path={`/${r.path}`} />
      })}
      <PublicRoute component={Page404} />
    </Switch>
  )
}
