export const calculateActiveShapeCoordinates = (
  cx: number,
  cy: number,
  midAngle: number,
  outerRadius: number
) => {
  const RADIAN = Math.PI / 180
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 50
  const ey = my
  const textAnchor = cos >= 0 ? "start" : "end"
  return {
    sx,
    sy,
    ex,
    ey,
    textAnchor,
    cos,
    mx,
    my
  }
}
