import { APTranslations } from "./AffectedParties"
import { apiRequest } from "./Api"
import { AttackersTranslations } from "./Attackers"
import { MOATranslations } from "./MeansOfAttack"

export interface Translations {
  title: string
  description: string
  outcome: string
  locale: string
  case_id: number
}
export interface CategoryTranslations {
  name: string
  locale: string
  category_id: number
}
export interface Category {
  category_parent_id: number
  id: number
  translations: CategoryTranslations[]
}
// export interface Country {
//   country_parent_id: number
//   id: number
//   translations: CountriesTranslations[]
// }
export interface CaseCategories {
  category_id: number
  case_category: Category
}
export interface CaseCountries {
  country_id: number
  // case_country: Country
}
export interface Case {
  id: number
  user_id: number
  start_date: string
  end_date: string
  // country: Country
  translations: Translations[]
  case_categories: CaseCategories[]
}
export interface Author {
  id: number
  username: string
  email: string
  role_id: number
}
export interface CaseAffectedParties {
  affected_party_id: number
  case_affected_party: { id: number; translations: APTranslations[] }
}
export interface CaseFiles {
  encoding: string
  filename: string
  id: number
  image_url: string
  mimetype: string
  object_id: number
  object_type: string
}
export interface AttackMean {
  attack_means_parent_id: number
  id: number
  translations: MOATranslations[]
}
export interface CaseMeansOfAttack {
  attack_means_id: number

  case_mean_of_attack: AttackMean
}
export interface Link {
  id: number
  link: string
  case_id: number
}
export interface CaseAttackers {
  attacker_id: number
  case_attacker: { id: number; translations: AttackersTranslations[] }
}

export interface CaseById {
  author: Author
  case_affected_parties: CaseAffectedParties[]
  case_attackers: CaseAttackers[]
  case_categories: CaseCategories[]
  // country: Country
  case_files: CaseFiles[]
  case_means_of_attack: CaseMeansOfAttack[]
  end_date: string
  start_date: string
  user_id: number
  links: Link[]
  translations: Translations[]
}
export interface CaseByIdResponse {
  case: CaseById
}
export interface CasesResponse {
  total_data: number
  rows: Case[]
}
export interface Filter {
  title?: string
  startDate?: string
  endDate?: string
  affectedPartiesId?: number[] | null
  attackersId?: number[] | null
  // country_id?: number[] | null
  meansOfAttacksId?: number[] | null
  categoriesId?: number[] | null
}

export interface CreateCaseInput extends FormData {}
export interface DeleteCaseResponse {}
interface CaseAffectedParty {
  affected_party_id: string
  case_id: number
  id: number
}
interface CaseAttacker {
  attacker_id: string
  case_id: number
  id: number
}
interface CaseCountry {
  country_id: string
  case_id: number
  id: number
}
interface CaseCategory {
  category_id: string
  case_id: number
  id: number
}
interface CaseMeansOfAttacks {
  means_of_attack_id: string
  case_id: number
  id: number
}
interface CaseLink {
  link: string
  case_id: number
  id: number
}
export interface CreateCaseResponse {
  case: {
    case_affected_party: CaseAffectedParty[]
    case_attackers: CaseAttacker[]
    // case_country: CaseCountry[]
    case_categories: CaseCategory[]
    case_means_of_attack: CaseMeansOfAttacks[]
    end_date: string
    start_date: string
    links: CaseLink[]
    translations: Translations[]
    user_id: number
    id: number
  }
}
export interface CasesInput {
  pageNumber: number
  filters: Filter
  locale?: string
}

export interface CategoryData {
  date: string
  count: number
}
export interface CategoriesOfCases {
  data: CategoryData[]
  translations: CategoryTranslations[]
  id: number
}
export interface CasesWithCountResponse {
  categories: CategoriesOfCases[]
  max: number
}

export const getAllCases = async (
  page?: number,
  filters?: Filter,
  locale?: string
) => {
  return apiRequest<CasesInput, CasesResponse>(
    "get",
    `api/cases?sortByDesc=start_date`,
    undefined,
    locale ? { locale } : undefined,
    {
      limit: 10,
      page,
      "filter[translations.title][ct]":
        filters?.title && filters?.title?.length > 0
          ? filters?.title
          : undefined,
      "filterByOr[translations.description][ct]":
        filters?.title && filters?.title?.length > 0
          ? filters?.title
          : undefined,
      "filter[case_affected_parties.case_affected_party.id][in]":
        filters?.affectedPartiesId && filters?.affectedPartiesId?.length > 0
          ? filters?.affectedPartiesId?.join()
          : undefined,
      "filter[case_attackers.case_attacker.id][in]":
        filters?.attackersId && filters.attackersId.length > 0
          ? filters?.attackersId?.join()
          : undefined,
      // "filter[country_id]":
      //   filters?.country_id && filters.country_id.length > 0
      //     ? filters?.country_id?.join()
      //     : undefined,
      "filter[case_means_of_attack.case_mean_of_attack.id][in]":
        filters?.meansOfAttacksId && filters?.meansOfAttacksId?.length > 0
          ? filters?.meansOfAttacksId?.join()
          : undefined,
      "filter[case_categories.case_category.id][in]":
        filters?.categoriesId && filters?.categoriesId?.length > 0
          ? filters?.categoriesId?.join()
          : undefined,
      "filter[start_date][gte]":
        filters?.startDate && filters?.startDate?.length > 0
          ? filters?.startDate?.toString()
          : undefined,
      "filter[start_date][lte]":
        filters?.endDate && filters?.endDate?.length > 0
          ? filters?.endDate?.toString()
          : undefined
    }
  )
}

export const getCaseById = async (caseId?: number, locale?: string) =>
  apiRequest<number, CaseByIdResponse>(
    "get",
    `api/cases/${caseId}`,
    undefined,
    locale ? { locale } : undefined
  )

export const downloadFile = async () =>
  apiRequest<undefined, File>("get", `api/downloads/excel`, undefined, {
    headers: { "Content-Disposition": "attachment; filename=Cases.xlsx" }
  })

export const createCase = async (data: CreateCaseInput) => {
  return apiRequest<CreateCaseInput, CreateCaseResponse>(
    "post",
    "api/cases",
    data
  )
}
export const updateCase = async (data: CreateCaseInput, caseId: number) => {
  return apiRequest<CreateCaseInput, CreateCaseResponse>(
    "put",
    `api/cases/${caseId}`,
    data
  )
}
export const deleteCase = async (caseId: number) => {
  return apiRequest<number, DeleteCaseResponse>("delete", `api/cases/${caseId}`)
}

export const getCasesWithCount = async (locale: string) =>
  apiRequest<undefined, CasesWithCountResponse>(
    "get",
    "api/cases/per-date-range/with-count",
    undefined,
    { locale }
  )
