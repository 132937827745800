import React from "react"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { useLabelsFormik } from "../../../lib/hooks/formik/useLablesFormik"
import { LabelForm } from "../LabelForm/LabelForm"

import * as API from "../../../api/Api"

interface Props {
  onAddLabel: (label: API.Label) => void
  toggle?: () => void
}

export const AddLabel = ({ onAddLabel, toggle, ...rest }: Props) => {
  const errorHandler = useErrorHandler()
  const formik = useLabelsFormik({
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.createUpdateLabel({
          key: values.key,
          value: values.value,
          locale: values.locale?.value
        })
        onAddLabel(res)
        toggle && toggle()
        formikHelpers.resetForm()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })
  return (
    <LabelForm
      toggle={toggle}
      errorHandler={errorHandler}
      formik={formik}
      {...rest}
    />
  )
}
