import React, { useState } from "react"
import { Label } from "../../../api/Labels"
import { useLabelsFormik } from "../../../lib/hooks/formik/useLablesFormik"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"

import * as API from "../../../api/Api"
import { Option } from "../../shared/Select/Select"
import { LabelForm } from "../LabelForm/LabelForm"
import { useTranslation } from "react-i18next"

interface Props {
  localeKey: string
  onEditLabel?: (label: Label) => void
  toggle?: () => void
}

export const EditLabel = ({
  localeKey,
  onEditLabel,
  toggle,
  ...rest
}: Props) => {
  const { i18n } = useTranslation()
  const errorHandler = useErrorHandler()
  const [locale, setLocale] = useState<Option | null>({
    label: i18n.language,
    value: i18n.language
  })

  const {
    loading: labelLoading,
    data: labelByKey,
    error: labelError
  } = useApiCall(API.getLabelByKey, [localeKey])

  const labelByLocale = labelByKey?.values.find(
    (label) => label.locale === locale?.value
  )

  const formik = useLabelsFormik({
    initialValues: {
      key: labelByKey?.key,
      value: labelByLocale?.value || "",
      locale: locale
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.createUpdateLabel({
          key: values.key,
          value: values.value,
          locale: values.locale?.value
        })
        onEditLabel && onEditLabel(res)
        toggle && toggle()
        formikHelpers.resetForm()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })

  const handleLocaleChange = (locale: Option) => {
    setLocale(locale)
  }

  return (
    <LabelForm
      localeKey={localeKey}
      errorHandler={errorHandler}
      labelLoading={labelLoading}
      labelError={labelError}
      onLocaleChange={handleLocaleChange}
      toggle={toggle}
      formik={formik}
      {...rest}
    />
  )
}
