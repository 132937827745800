export const formatOptions = (key?: string, rows?: any[]) => {
  if (!key) return
  if (!rows || rows.length === 0) return []

  let arr: any[] = []
  rows.forEach((row) => {
    if (row.children && row.children.length > 0) {
      arr.push({
        label: row.translations[0].name,
        options:
          row.children &&
          row.children.map((child: any) => {
            return {
              value: child.translations[0]?.[key]?.toString(),
              label: child.translations[0]?.name
            }
          })
      })
      return
    }

    arr.push({
      value: row?.translations[0]?.[key]?.toString(),
      label: row?.translations[0]?.name
    })
  })
  return arr
}
