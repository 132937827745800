import { useFormik, FormikHelpers } from "formik"
import * as Yup from "yup"

export interface LoginFields {
  username: string
  password: string
}

interface UseLoginFormOptions {
  initialValues?: LoginFields
  onSubmit: (
    values: LoginFields,
    formikHelpers: FormikHelpers<LoginFields>
  ) => Promise<any>
}

export const useLoginFormik = (options: UseLoginFormOptions) => {
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required("Username is required!"),
    password: Yup.string()
      .min(6, "Your password must be minimum 6 characters long")
      .required("Password is required!")
  })

  return useFormik({
    initialValues: {
      username: "",
      password: ""
    },
    isInitialValid: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values, formikHelpers) => {
      await options.onSubmit(values, formikHelpers)
    }
  })
}

export type LoginFormik = ReturnType<typeof useLoginFormik>
