import React from "react"
import { UsersFormik } from "../../../lib/hooks/formik/useUsersFormik"
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage"
import { Input } from "../../shared/Input/Input"
import { Select } from "../../shared/Select/Select"
import { RolesResponse } from "../../../api/Api"
import { UseErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { FormButtons } from "../../shared/FormButtons/FormButtons"

import "./UsersForm.scss"
import { useTranslation } from "react-i18next"

export interface Roles {
  error: any
  loading: boolean
  args: [] | undefined
  data: RolesResponse | undefined
  reload: () => void
}
interface Props {
  toggle?: () => void
  userId?: number
  inputIsLoading?: boolean
  formik: UsersFormik
  roles?: Roles
  error?: string
  errorHandler?: UseErrorHandler
  inputHasError?: boolean
}

export const UsersForm = ({
  inputIsLoading,
  formik,
  toggle,
  errorHandler,
  roles,
  userId,
  inputHasError
}: Props) => {
  const { t } = useTranslation()
  return (
    <div className="UsersForm">
      <form className="UsersForm__form" onSubmit={formik.handleSubmit}>
        <Input
          className="UsersForm__input"
          type="text"
          name="username"
          id="username"
          placeholder={inputIsLoading ? t("Loading...") : t("User name")}
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.username}
          error={formik.errors.username || inputHasError}
        />
        <Input
          className="UsersForm__input"
          type="text"
          name="email"
          id="email"
          placeholder={inputIsLoading ? t("Loading...") : t("Email")}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email}
          error={formik.errors.email || inputHasError}
        />
        {userId && (
          <Input
            className="UsersForm__input"
            type="password"
            name="current_password"
            id="current_password"
            placeholder={
              inputIsLoading ? t("Loading...") : t("Current Password")
            }
            value={formik.values.current_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.current_password}
            error={formik.errors.current_password || inputHasError}
          />
        )}
        <Input
          className="UsersForm__input"
          type="password"
          name="password"
          id="password"
          placeholder={inputIsLoading ? t("Loading...") : t("Password")}
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.password}
          error={formik.errors.password}
        />
        <Select
          loading={roles?.loading}
          isMulti={false}
          isClearable
          closeMenuOnSelect
          placeholder="Role id"
          name="role_id"
          id="role_id"
          options={roles?.data?.rows.map((row) => ({
            label: row.name,
            value: row.id.toString()
          }))}
          onChange={(option) => {
            formik.setFieldValue("role_id", option)
            formik.setFieldTouched("role_id")
          }}
          value={formik.values.role_id}
          error={formik.errors.role_id}
          // onBlur={formik.handleBlur}
          touched={formik.touched.role_id}
        />
        <FormButtons
          toggle={toggle}
          disabled={!formik.isValid || formik.isSubmitting}
          isSubmitting={formik.isSubmitting}
        />

        <ErrorMessage errorData={errorHandler} onClose={errorHandler?.reset} />
      </form>
    </div>
  )
}
