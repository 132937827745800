import React from "react"
import { AffectedParties } from "../../components/AffectedParties/AffectedParties"
import { MeansOfAttacks } from "../../components/MeansOfAttacks/MeansOfAttacks"
import { useWindowWidth } from "../../lib/hooks/useWindowWidth"
import { Categories } from "../../components/Categories/Categories"
import { Attackers } from "../../components/Attackers/Attackers"
import { Overview } from "../../components/Overview/Overview"
import { Timeline } from "../../components/Timeline/Timeline"
import { Section } from "../../components/shared/Section/Section"
import { useTranslation } from "react-i18next"

//dumbData

//styles
import "./Home.scss"

export const Home = () => {
  const { t } = useTranslation()
  const width = useWindowWidth()

  return (
    <div className="Home">
      <Overview className="Home__overview" />

      <Section title={t("Timeline")}>
        <Timeline />
      </Section>

      <Section title={t("Category of violation")}>
        <Categories width={width} />
      </Section>

      <Section title={t("Means of violation")} subtitle={t("Technical")}>
        <MeansOfAttacks index={0} />
      </Section>

      <Section subtitle={t("Legal")}>
        <MeansOfAttacks index={1} />
      </Section>

      <div className="Home__affected_parties">
        <Section title={t("Affected parties")}>
          <AffectedParties />
        </Section>

        <Section title={t("Attackers")}>
          <Attackers />
        </Section>
      </div>
    </div>
  )
}
