import { useCallback, useState } from "react"

export const useOpacity = () => {
  const [activeIndex, setActiveIndex] = useState<number>()

  const handleMouseEnter = useCallback(
    (_, index: number) => {
      setActiveIndex(index)
    },
    [setActiveIndex]
  )

  const handleMouseLeave = useCallback(
    (_, index: number) => {
      setActiveIndex(undefined)
    },
    [setActiveIndex]
  )

  return { activeIndex, handleMouseEnter, handleMouseLeave }
}
