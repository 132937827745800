import { apiRequest } from "./Api"
import { CategoryTranslations } from "./Cases"

interface CChildren {
  id: number
  category_parent_id: number
  translations: CategoryTranslations[]
}
export interface CRows {
  id: number
  children: CChildren[]
  category_parent_id: number | null
  translations: CategoryTranslations[]
}
export interface CategoryResponse {
  total_data: number
  rows: CRows[]
}
export interface ChildCategories {
  cases_count: number
  category_parent_id: number
  id: number
  translations: CategoryTranslations[]
}
export interface ParentCategories {
  category_parent_id: string
  id: number
  translations: CategoryTranslations[]
}
export interface CategoriesWithCount {
  childCategories: ChildCategories[]
  parentCategories: ParentCategories[]
}

export const getAllCategories = async (locale: string) =>
  apiRequest<undefined, CategoryResponse>(
    "get",
    "api/categories?sortByAsc=translations.category_id,children.translations.name",
    undefined,
    {
      locale
    }
  )

export const getCategoriesWithCount = async (locale: string) =>
  apiRequest<undefined, CategoriesWithCount>(
    "get",
    "api/categories/cases-count",
    undefined,
    {
      locale
    }
  )
