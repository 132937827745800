import React from "react"

import "./Tooltip.scss"

export interface Payload {
  color: string
  value: number
  name: string
  dataKey: string
  radius: number[]
}

interface Props {
  active?: boolean
  payload?: Payload[]
  label?: string
  showLabel?: boolean
}
export const Tooltip = ({ active, payload, showLabel }: Props) => {
  if (active && payload && payload.length) {
    return (
      <ul className="Tooltip">
        {payload.map((entry: any, index) => {
          return (
            <li key={`Tooltip-${index}`} className="Tooltip__value">
              {showLabel && entry.name}
              <div>{entry.value}</div>
            </li>
          )
        })}
      </ul>
    )
  }
  return null
}
