import { apiRequest } from "./Api"

export interface APTranslations {
  name: string
  locale: string
  affected_party_id: number
}
export interface APRows {
  id: number
  translations: APTranslations[]
}
export interface AffectedPartiesResponse {
  total_data: number
  rows: APRows[]
}

export interface AffectedPartiesWithCountResponse {}

export const getAllAffectedParties = async (locale: string) =>
  apiRequest<undefined, AffectedPartiesResponse>(
    "get",
    "api/affected-parties?sortByAsc=translations.name&relations=translations",
    undefined,
    { locale }
  )
export const getAffectedPartiesWithCount = async (locale: string) =>
  apiRequest<undefined, AffectedPartiesWithCountResponse>(
    "get",
    "api/affected-parties/cases-count",
    undefined,
    { locale }
  )
