import React, { useState } from "react"
import {
  FormFeedback,
  FormGroup,
  Input as RInput,
  InputProps as RInputProps
} from "reactstrap"
import { Icon, IconDefinition } from "../Icon/Icon"
import cs from "classnames"
//styles
import "./Input.scss"

export interface InputProps extends RInputProps {
  icon?: IconDefinition
  inputClassName?: string
  wrapperClassName?: string
  loading?: boolean
  touched?: boolean
  error?: any
  submitting?: boolean
  selectid?: string
}

export const Input = (props: InputProps) => {
  const {
    placeholder,
    className,
    wrapperClassName,
    icon,
    inputClassName,
    loading,
    touched,
    error,
    submitting,
    ...rest
  } = props

  const [isActive, setIsActive] = useState<boolean>(false)
  setTimeout(() => {
    if (props.value) {
      setIsActive(true)
    }
  }, 100)

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    setIsActive(text !== "")
    props.onChange && props.onChange(e)
  }

  return (
    <FormGroup className={cs("FormGroup", className)}>
      <div className={cs("Input__wrapper", wrapperClassName)}>
        <RInput
          className={cs("Input", inputClassName, { "Input-active": isActive })}
          value={loading ? "Loading..." : props.value}
          onChange={handleTextChange}
          placeholder={placeholder}
          ref={props.inputRef}
          touched={touched}
          {...rest}
        />

        {icon && (
          <Icon
            className={cs("Input__icon", { Input__loading: submitting })}
            icon={icon}
          />
        )}
      </div>
      {error && touched && (
        <FormFeedback className="Input__error">{props.error}</FormFeedback>
      )}
    </FormGroup>
  )
}
