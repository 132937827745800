import React from "react"
import cs from "classnames"

import "./TableWrapper.scss"

interface TableWrapperProps {
  children: JSX.Element[] | JSX.Element
  className?: string
}

export const TableWrapper = ({ children, className }: TableWrapperProps) => {
  return (
    <div className="TableWrapper__scrollable">
      <div className={cs("TableWrapper", className)}>{children}</div>
    </div>
  )
}
