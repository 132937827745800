import React, { useCallback, useEffect, useState } from "react"
import { HandleErrorState } from "../../shared/HandleErrorState/HandleErrorState"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { useLabelsRows } from "./utils/useLabelsRows"
import { AddNewButton } from "../../shared/AddNewButton/AddNewButton"
import { TableWrapper } from "../../shared/TableWrapper/TableWrapper"
import { LabelModal } from "../LabelModal/LabelModal"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { Table } from "../../shared/Table/Table"
import { useTranslation } from "react-i18next"

import * as API from "../../../api/Api"

import "./LabelsTable.scss"

export const LabelsTable = () => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [localeKey, setLocaleKey] = useState<string>()
  const [loading, setLoading] = useState<boolean>(true)
  const [labels, setLabels] = useState<API.LabelsResponse>()
  const errorHandler = useErrorHandler()

  const { loading: isLoading, data: languages, error: isError } = useApiCall(
    API.getLanguages
  )

  const languagesColumns =
    languages?.rows.map((lang) => ({
      key: lang.locale,
      header: lang.language
    })) || []

  const toggleModal = () => {
    setLocaleKey(undefined)
    setShowModal(!showModal)
  }
  const handleEditClick = (key: string) => {
    toggleModal()
    setLocaleKey(key)
  }

  const getLabels = useCallback(async () => {
    try {
      const res = await API.getAllLabels()
      setLabels(res)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getLabels()
  }, [getLabels])

  const handleEditLabel = (label: API.Label) => {
    const loc = label.locale as keyof API.Label
    setLabels((prevState) => {
      if (!prevState) {
        return {
          [loc]: [{ key: label.key || "", value: label.value || "" }]
        }
      }

      const hasKey = prevState[loc].find((item) => item.key === label.key)

      if (!hasKey) {
        return {
          ...prevState,
          [loc]: [
            ...prevState[loc],
            { key: label.key || "", value: label.value || "" }
          ]
        }
      }

      const newVal = prevState[loc].map((item) => {
        if (item.key === label.key) {
          return { key: label.key || "", value: label.value || "" }
        }

        return item
      })
      return { ...prevState, [loc]: newVal }
    })
  }

  const handleAddLabel = (label: API.Label) => {
    const loc = label.locale as keyof API.Label
    setLabels((prevState) => {
      if (!prevState) {
        return {
          [loc]: [{ key: label.key || "", value: label.value || "" }]
        }
      }

      return {
        ...prevState,
        [loc]: [
          ...prevState[loc],
          { key: label.key || "", value: label.value || "" }
        ]
      }
    })
  }
  //table properties
  const columns = [
    { key: "key", header: t("Key"), width: 350 },
    ...languagesColumns,
    { key: "actions", header: "" }
  ]
  const rows = useLabelsRows(handleEditClick, labels)
  return (
    <div className="LabelsTable">
      <AddNewButton onClick={toggleModal} />

      <HandleErrorState error={isError}>
        <TableWrapper>
          <Table rows={rows} loading={loading || isLoading} columns={columns} />
        </TableWrapper>
      </HandleErrorState>
      <LabelModal
        onEditLabel={handleEditLabel}
        onAddLabel={handleAddLabel}
        localeKey={localeKey}
        toggle={toggleModal}
        isOpen={showModal}
        centered
      />
    </div>
  )
}
