import React from "react"
import { formatDataForChart } from "../../lib/helpers/formatDataForChart"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { useTranslation } from "react-i18next"
import { PieChart } from "../shared/charts/PieChart/PieChart"

import * as API from "../../api/Api"
import { useWindowWidth } from "../../lib/hooks/useWindowWidth"
import { getChartColors } from "../../lib/helpers/getChartColors"

export const Attackers = () => {
  const { i18n } = useTranslation()
  const {
    loading: attackersLoading,
    data: attackers,
    error: attackersError
  } = useApiCall(API.getAttackersWithCount, [i18n.language])

  const attackersData = formatDataForChart("attackers", attackers)
  const colors = getChartColors(354)
  const width = useWindowWidth()
  return (
    <PieChart
      id="attackers"
      loading={attackersLoading}
      error={attackersError}
      data={attackersData}
      colors={colors}
      outerRadius={width < 600 ? 100 : 160}
    />
  )
}
