import React from "react"
import cs from "classnames"

import "./FormWrapper.scss"
interface Props {
  children: JSX.Element | JSX.Element[]
  className?: string
}

export const FormWrapper = ({ children, className }: Props) => {
  return <div className={cs("FormWrapper", className)}>{children}</div>
}
