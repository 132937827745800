import React from "react"
import { Table, TableColumn, TableRow } from "../shared/Table/Table"
import { HandleErrorState } from "../shared/HandleErrorState/HandleErrorState"
import { TableWrapper } from "../shared/TableWrapper/TableWrapper"
import { TablePagination } from "../shared/TablePagination/TablePagination"

interface CasesTableProps {
  error?: any
  loading?: boolean
  onPageChange: (pageNumber: number) => void
  rows?: TableRow[]
  columns: TableColumn[]
  caseId?: number
  showModal: boolean
  totalData?: number
  toggleModal: () => void
  currentPage?: number
}

export const ROOT_DOWNLOAD_URL = `${process.env.REACT_APP_API_URL}/api/downloads/excel`

export const CasesTable = ({
  error,
  totalData,
  rows,
  caseId,
  columns,
  loading,
  onPageChange,
  showModal,
  currentPage,
  toggleModal,
  ...rest
}: CasesTableProps) => {
  return (
    <HandleErrorState error={error}>
      <TableWrapper>
        <Table columns={columns} rows={rows} loading={loading} totalData={totalData} />
      </TableWrapper>

      <TablePagination
        page={currentPage}
        onPageChange={onPageChange}
        pages={totalData}
      />
    </HandleErrorState>
  )
}
