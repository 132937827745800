import React from "react"

//components
import { Modal as ModalRS } from "reactstrap"
import cs from "classnames"
//styles
import "./Modal.scss"
import { Icon } from "../Icon/Icon"

export interface ModalProps {
  className?: string
  isOpen?: boolean
  children?: React.ReactChild[] | React.ReactChild
  title?: string
  big?: boolean
  toggle?: () => void
  centered?: boolean
  fade?: boolean
  contentClassName?: string
}

export const Modal = (props: ModalProps) => {
  const {
    className,
    isOpen,
    toggle,
    contentClassName,
    title,
    children,
    ...rest
  } = props
  if (!props.isOpen) return null
  return (
    <ModalRS
      className={cs("Modal", className)}
      isOpen={isOpen}
      toggle={toggle}
      contentClassName={cs("Modal__content", contentClassName)}
      {...rest}
    >
      <button className="Modal__close" onClick={toggle}>
        <Icon icon="close" />
      </button>
      {title && <h3>{title}</h3>}
      {children}
    </ModalRS>
  )
}
