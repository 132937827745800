import React, { useState } from "react"
import {
  Dropdown as RDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  DropdownProps
} from "reactstrap"
import { HandleErrorState } from "../HandleErrorState/HandleErrorState"

import cs from "classnames"

import "./Dropdown.scss"
import { useTranslation } from "react-i18next"

interface Props extends DropdownProps {
  className?: string
  items?: { page: string; path: string }[]
  itemclick?: (item: string) => void
  toggleClassName?: string
  itemClassName?: string
  toggleHeader?: string | null
  menuClassName?: string
  button?: JSX.Element
  icon?: JSX.Element
  loading?: boolean
  error?: string
}

export const Dropdown = ({
  className,
  items,
  itemclick,
  toggleClassName,
  itemClassName,
  menuClassName,
  toggleHeader,
  button,
  icon,
  loading,
  error,
  ...rest
}: Props) => {
  const { i18n } = useTranslation()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string | null>(i18n.language)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const handleItemClick = (
    item: { page: string; path: string },
    e: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSelectedItem(item.page)
    itemclick && itemclick(item.path)
  }

  return (
    <RDropdown
      className={cs("Dropdown", className)}
      isOpen={dropdownOpen}
      toggle={toggle}
      {...rest}
    >
      <HandleErrorState error={error}>
        <DropdownToggle className={cs("Dropdown__toggle", toggleClassName)}>
          {toggleHeader
            ? toggleHeader
            : selectedItem
            ? selectedItem
            : items?.[0].page}
          {icon && icon}
        </DropdownToggle>
        <DropdownMenu className={cs("Dropdown__menu", menuClassName)} right>
          {items?.map((item) => (
            <DropdownItem
              key={`Dropdown-${item.page}`}
              className={cs("Dropdown__item", itemClassName, {
                Dropdown__item_isActive: selectedItem === item.page
              })}
              onClick={(e) => {
                handleItemClick(item, e)
              }}
            >
              {item.page}
            </DropdownItem>
          ))}
          {button && button}
        </DropdownMenu>
      </HandleErrorState>
    </RDropdown>
  )
}
