import React from "react"
import { useTranslation } from "react-i18next"
import share_monitoring_logo from "../../../assets/icons-png/monitoring-logo-1.png"
import { useRouter } from "../../../lib/hooks/useRouter"

import "./Information.scss"

export const Information = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation()
  const router = useRouter()
  return (
    <div className="Information">
      <img
        alt="logo"
        className="Information__logo"
        src={share_monitoring_logo}
        onClick={() => router.history.push(`/${language}/`)}
      />
      <div className="Information__line"></div>
      <p className="Information__description">
        {t("Keeping track of online violations in Southern and Eastern Europe")}
      </p>
    </div>
  )
}
