import { Filter } from "../../api/Cases"
import { ROOT_DOWNLOAD_URL } from "../../components/CasesTable/CasesTable"

const removeFalsyElement = (obj: { [key: string]: any }) => {
  const newObject = {} as { [key: string]: any }

  Object.keys(obj).forEach((key) => {
    if (obj[key] && obj[key].length > 0) {
      newObject[key] = obj[key]
    }
  })
  return newObject
}

export const getDownloadUrl = (
  pageNumber: number,
  filters: Filter,
  language: string
) => {
  const activeFilters = removeFalsyElement(filters)

  //IF THERE ARE NO FILTERS, IT MEANS THE BUTTON CLEAR IS CLICKED, IF THEN RETURN THE BASE URL
  //THE LANGUAGE PREFIX IS SETTED ON THE DOWNLOAD BUTTON HREF PROPERTY, BECAUSE IT NEEDS TO GET THE CURRENT LANGUAGE WHEN IT IS CLICKED
  //Example: locale=en
  if (Object.keys(activeFilters).length === 0)
    return ROOT_DOWNLOAD_URL + `?locale=`

  let urlWithFilter = ""

  if (pageNumber) {
    urlWithFilter += `?`
  }
  if (activeFilters.title) {
    urlWithFilter +=
      activeFilters.title.length > 0 ? `&title=${activeFilters.title}` : ""
  }
  if (activeFilters.affectedPartiesId) {
    urlWithFilter += `&filter[case_affected_parties.case_affected_party.id][in]=${activeFilters.affectedPartiesId}`
  }

  if (activeFilters.attackersId) {
    urlWithFilter += `&filter[case_attackers.case_attacker.id][in]=${activeFilters.attackersId}`
  }
  if (activeFilters.meansOfAttacksId) {
    urlWithFilter += `&filter[case_means_of_attack.case_mean_of_attack.id][in]=${activeFilters.meansOfAttacksId}`
  }
  if (activeFilters.categoriesId) {
    urlWithFilter += `&filter[case_categories.case_category.id][in]=${activeFilters.categoriesId}`
  }
  if (activeFilters.startDate) {
    urlWithFilter += `&filter[start_date][gte]=${activeFilters.startDate}`
  }
  if (activeFilters.endDate) {
    urlWithFilter += `&filter[start_date][lte]=${activeFilters.endDate}`
  }

  //THE LANGUAGE PREFIX IS SETTED ON THE DOWNLOAD BUTTON HREF PROPERTY, BECAUSE IT NEEDS TO GET THE CURRENT LANGUAGE WHEN IT IS CLICKED
  //Example: locale=en
  if (language) {
    urlWithFilter += `&locale=`
  }

  return ROOT_DOWNLOAD_URL + urlWithFilter
}
