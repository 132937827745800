import React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
export const ChartPlaceholder = () => {
  return (
    <SkeletonTheme color="#675656" highlightColor="#1C1616">
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Skeleton
          style={{ marginLeft: "50px", marginRight: "20px" }}
          count={1}
          width="150px"
          height="400px"
        />
        <Skeleton
          style={{ marginTop: "200px", marginRight: "20px" }}
          count={2}
          width="150px"
          height="200px"
        />
        <Skeleton
          style={{ marginRight: "20px" }}
          count={1}
          width="150px"
          height="400px"
        />
        <Skeleton
          style={{ marginTop: "200px", marginRight: "20px" }}
          count={1}
          width="150px"
          height="200px"
        />
        <Skeleton
          style={{ marginLeft: "50px", marginRight: "20px" }}
          count={2}
          width="150px"
          height="400px"
        />
      </div>
    </SkeletonTheme>
  )
}
