import React from "react"
import { Modal, ModalProps } from "../../shared/Modal/Modal"
import { ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { ModalParagraph } from "./ModalParagraph/ModalParagraph"
import { Button } from "../../shared/Button/Button"
import { HandleLoadingState } from "../../shared/HandleLoadingState/HandleLoadingState"
import { HandleErrorState } from "../../shared/HandleErrorState/HandleErrorState"

import * as API from "../../../api/Api"

import "./ReadMoreModal.scss"
import { useTranslation } from "react-i18next"
import { FileInput } from "../../shared/FileInput/FileInput"
import { FILES_PATH } from "../../../lib/helpers/formatInitialValues"
import { format } from "date-fns"

interface Props extends ModalProps {
  caseId: number
}

export const ReadMoreModal = ({ isOpen, fade, toggle, caseId }: Props) => {
  const { i18n } = useTranslation()

  const {
    loading: isLoading,
    data: caseById,
    error
  } = useApiCall(API.getCaseById, [caseId, i18n.language])
  const { t } = useTranslation()

  const caseInfo = caseById?.case.translations[0]

  const affectedParties = caseById?.case.case_affected_parties.map(
    ({ case_affected_party }) => case_affected_party.translations[0].name
  )
  const attacker = caseById?.case.case_attackers.map(
    ({ case_attacker }) => case_attacker.translations[0].name
  )
  const category = caseById?.case.case_categories.map(
    ({ case_category }) => case_category.translations[0].name
  )
  const meansOfAttack = caseById?.case.case_means_of_attack.map(
    ({ case_mean_of_attack }) => case_mean_of_attack.translations[0].name
  )
  const startDate = caseById?.case.start_date
  const endDate = caseById?.case.end_date
  const description = caseById?.case.translations[0].description
  const outcome = caseById?.case.translations[0].outcome
  const links = caseById?.case.links.map((link) => link.link)

  return (
    <>
      {caseInfo && (
        <Modal
          className="ReadMoreModal"
          isOpen={isOpen}
          fade={fade}
          toggle={toggle}
          centered
        >
          <ModalHeader className="ReadMoreModal__header">
            {t("case")}
          </ModalHeader>
          <HandleErrorState error={error}>
            <HandleLoadingState loading={isLoading}>
              <ModalBody className="ReadMoreModal__body">
                <ModalParagraph title={t("Title")}>
                  {caseInfo?.title}
                </ModalParagraph>
                <ModalParagraph title={t("Affected parties")}>
                  {affectedParties?.join()}
                </ModalParagraph>
                <ModalParagraph title={t("Attackers")}>
                  {attacker?.join()}
                </ModalParagraph>
                <ModalParagraph title={t("Categories")}>
                  {category?.join()}
                </ModalParagraph>
                <ModalParagraph title={t("Means of attack")}>
                  {meansOfAttack?.join()}
                </ModalParagraph>
                <div className="ReadMoreModal__dates">
                  <ModalParagraph
                    className="ReadMoreModal__start"
                    title={t("Start date")}
                  >
                    {startDate && format(new Date(startDate), "MM/dd/yyyy")}
                  </ModalParagraph>
                  <div className="ReadMoreModal__divider"></div>
                  <ModalParagraph
                    className="ReadMoreModal__end"
                    title={t("End date")}
                  >
                    {endDate && format(new Date(endDate), "MM/dd/yyyy")}
                  </ModalParagraph>
                </div>
                <ModalParagraph title={t("Description")}>
                  {description}
                </ModalParagraph>
                <ModalParagraph title={t("Outcome")}>{outcome}</ModalParagraph>
                <ModalParagraph title={t("Files")}>
                  <FileInput
                    allowMultiple
                    allowDrop={false}
                    allowBrowse={false}
                    allowPaste={false}
                    files={caseById?.case.case_files.map(
                      (caseFile) => `${FILES_PATH}/${caseFile.filename}`
                    )}
                  />
                </ModalParagraph>
                <ModalParagraph
                  contentClassName="ReadMoreModal__links"
                  title={t("links")}
                >
                  {links?.map((link) => (
                    <a
                      className="ReadMoreModal__link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={link}
                    >
                      {link}
                    </a>
                  ))}
                </ModalParagraph>
              </ModalBody>
            </HandleLoadingState>
          </HandleErrorState>
          <ModalFooter className="ReadMoreModal__footer">
            <Button className="ReadMoreModal__close" onClick={toggle}>
              {t("Close")}
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  )
}
