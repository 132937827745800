import React from "react"
import { HandleLoadingState } from "../shared/HandleLoadingState/HandleLoadingState"
import { HandleErrorState } from "../shared/HandleErrorState/HandleErrorState"
import { useApiCall } from "../../lib/hooks/useApiCall"

import { TextPlaceholder } from "../shared/TextPlaceholder/TextPlaceholder"
import { useTranslation } from "react-i18next"
import * as API from "../../api/Api"

import cs from "classnames"
import "./Overview.scss"

interface Props {
  className?: string
}

export const Overview = (props: Props) => {
  const { i18n } = useTranslation()
  const { loading, data: pageById, error } = useApiCall(API.getPageById, [
    1,
    i18n.language
  ])
  const methodologyContent = pageById?.page?.translations?.[0].content

  return (
    <div className={cs("Overview", props.className)}>
      <HandleLoadingState
        loading={loading}
        placeholder={<TextPlaceholder width={800} count={5} />}
      >
        <HandleErrorState error={error}>
          {" "}
          <div dangerouslySetInnerHTML={{ __html: methodologyContent || "" }} />
        </HandleErrorState>
      </HandleLoadingState>
    </div>
  )
}
