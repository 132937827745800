import React from "react"
import { useTranslation } from "react-i18next"
import { Route, RouteProps, useHistory } from "react-router-dom"
import { useAuthContext } from "../lib/context/AuthContext/AuthContext"
export const PrivateRoute = (props: RouteProps) => {
  const {
    i18n: { language }
  } = useTranslation()
  const authCtx = useAuthContext()
  const history = useHistory()

  if (!authCtx.isAuthenticated) {
    history.push(
      `/${language}/login?redirect=${encodeURIComponent(
        history.location.pathname
      )}`
    )
  }

  return <Route {...props} />
}
