import React from "react"
import { Icon } from "../../components/shared/Icon/Icon"

//styles
import "./Page404.scss"

export const Page404 = () => {
  return (
    <div className="Page404">
      <Icon className="Page404__icon" icon="error-404" />
      <h1>
        Nothing found <b>404</b>!
      </h1>
    </div>
  )
}
