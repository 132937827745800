import React from "react"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { useLanguageFormik } from "../../../lib/hooks/formik/useLanguageFormik"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { LanguageForm } from "../LanguageForm/LanguageForm"

import * as API from "../../../api/Api"

interface Props {
  langId: number
  toggle?: () => void
  onEditLanguage: (language: API.AddEditLanguageResponse) => void
}
export const EditLanguage = ({ langId, onEditLanguage, toggle }: Props) => {
  const { loading, data: langById, error } = useApiCall(API.getLanguageById, [
    langId
  ])

  const errorHandler = useErrorHandler()
  const formik = useLanguageFormik({
    initialValues: {
      language: langById?.language || "",
      locale: langById?.locale || ""
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.editLanguage(values)
        onEditLanguage(res)
        toggle && toggle()
        formikHelpers.resetForm()
      } catch (error) {
        errorHandler.handleError(error)
      }
    }
  })
  return (
    <LanguageForm
      loading={loading}
      error={error}
      errorHandler={errorHandler}
      toggle={toggle}
      formik={formik}
    />
  )
}
