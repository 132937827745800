import React from "react"
import { Icon, IconDefinition } from "../Icon/Icon"
import { useRouter } from "../../../lib/hooks/useRouter"
import { Button as RButton, ButtonProps as RButtonProps } from "reactstrap"
//styles
import cs from "classnames"
import "./Button.scss"

export interface ButtonProps extends RButtonProps {
  type?: "button" | "reset" | "submit" | undefined
  icon?: IconDefinition
  children?: string | JSX.Element[] | JSX.Element
  className?: string
  disabled?: boolean
  loading?: boolean
  onClick?: (e: any) => void
  to?: string
  iconclassname?: string
}

export const Button = (props: ButtonProps) => {
  const router = useRouter()
  const { className, loading, type, disabled, ...rest } = props

  const handleClick = (e: any) => {
    if (!props.to) {
      if (props.onClick) {
        props.onClick(e)
      }
      return
    }

    router.history.push(props.to)
  }

  return (
    <RButton
      disabled={disabled}
      className={cs("Button", className)}
      onClick={handleClick}
      type={type}
      {...rest}
    >
      {props.children}
      {props.icon && (
        <Icon
          className={cs("Icon__aside", props.iconclassname)}
          icon={props.icon}
        />
      )}
    </RButton>
  )
}
