import React from "react"
import { formatLineChartData } from "../../lib/helpers/formatLineChartData"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { LineChart } from "../shared/charts/LineChart/LineChart"
import { useTranslation } from "react-i18next"

import * as API from "../../api/Api"

export const Timeline = () => {
  const { i18n } = useTranslation()
  const { loading, data: cases, error } = useApiCall(API.getCasesWithCount, [
    i18n.language
  ])

  const { lineChartData, parents } = formatLineChartData(cases)

  return (
    <LineChart
      loading={loading}
      error={error}
      id="categories"
      data={lineChartData}
      parents={parents}
      max={cases?.max}
    />
  )
}
