import React from "react"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import { useErrorHandler } from "../../../lib/hooks/useErrorHandler"
import { useUsersFormik } from "../../../lib/hooks/formik/useUsersFormik"

import * as API from "../../../api/Api"
import { Roles, UsersForm } from "../UsersForm/UsersForm"

interface Props {
  userId: number
  roles: Roles
  onEditUser: (user: API.UserRow) => void
  toggle?: () => void
}

export const EditUser = ({
  userId,
  roles,
  onEditUser,
  toggle,
  ...rest
}: Props) => {
  const {
    loading: inputIsLoading,
    data: userById,
    error: inputHasError
  } = useApiCall(API.getUserById, [userId])

  const role = roles.data?.rows.find((row) => row.id === userById?.user.role_id)
  const errorHandler = useErrorHandler()

  const formik = useUsersFormik({
    initialValues: {
      email: userById?.user.email || "",
      role_id:
        { value: role?.id.toString() || "", label: role?.name || "" } || null,
      username: userById?.user.username || ""
    },
    onSubmit: async (values, formikHelpers) => {
      try {
        const res = await API.updateUser(
          {
            username: values.username,
            password: values.password || "",
            current_password: values.current_password || "",
            email: values.email,
            role_id: parseInt(values.role_id?.value || "")
          },
          userId
        )

        onEditUser(res.user)
        toggle && toggle()
        formikHelpers.resetForm()
      } catch (e) {
        errorHandler.handleError(e)
      }
    }
  })
  return (
    <UsersForm
      formik={formik}
      roles={roles}
      errorHandler={errorHandler}
      inputIsLoading={inputIsLoading}
      inputHasError={inputHasError}
      userId={userId}
      toggle={toggle}
      {...rest}
    />
  )
}
