import React, { useCallback, useEffect, useState } from "react"
import { Button } from "../../components/shared/Button/Button"
import { HandleErrorState } from "../../components/shared/HandleErrorState/HandleErrorState"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { TableWrapper } from "../../components/shared/TableWrapper/TableWrapper"
import { Table } from "../../components/shared/Table/Table"
import { useUsersRows } from "../../components/Users/UsersTable/utils/useUsersRows"
import { UsersModal } from "../../components/Users/UsersModal/UsersModal"
import { TablePagination } from "../../components/shared/TablePagination/TablePagination"
import { useTranslation } from "react-i18next"

import "./UsersDashboard.scss"

import * as API from "../../api/Api"

export const UsersDashboard = () => {
  const { t } = useTranslation()
  const [pageNumber, setPageNumber] = useState(1)
  const [isDeleting, setIsDeleting] = useState(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [userId, setUserId] = useState<number>()
  const [users, setUsers] = useState<API.UsersResponse>()
  const errorHandler = useErrorHandler()

  const getAllUsers = useCallback(async () => {
    try {
      const res = await API.getAllUsers(pageNumber)
      setUsers(res)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber])

  useEffect(() => {
    getAllUsers()
  }, [getAllUsers])

  //pagination
  const handlePageChange = (currentPage: number) => {
    setPageNumber(currentPage)
  }
  //Edit user click
  const handleEditClick = async (id: number) => {
    toggleModal()
    setUserId(id)
  }
  //delete user
  const handleDeleteClick = async (userId: number) => {
    try {
      setUserId(userId)
      setIsDeleting(true)
      await API.deleteUser(userId)
      setUsers(
        (prevState) =>
          prevState && {
            total_data: prevState.total_data,
            rows: prevState.rows.filter((r) => r.id !== userId)
          }
      )
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setIsDeleting(false)
    }
  }

  //add user
  const handleAddUser = (user: API.UserRow) => {
    user &&
      setUsers(
        (prevState) =>
          prevState && {
            total_data: prevState?.total_data,
            rows: [...prevState?.rows, user]
          }
      )
  }
  //edit user
  const handleEditUser = async (user: API.UserRow) => {
    setUsers(
      (prevState) =>
        prevState && {
          total_data: prevState.total_data,
          rows: prevState.rows.map((row) => {
            if (row.id === user.id) {
              return user
            }
            return row
          })
        }
    )
  }
  //open or close  modal
  const toggleModal = () => {
    setUserId(undefined)
    setShowModal(!showModal)
  }

  //table properties
  const columns = [
    { key: "email", header: t("Email") },
    { key: "username", header: t("Username") },
    { key: "edit", header: "", width: 50 },
    { key: "delete", header: "", width: 70 }
  ]
  const rows = useUsersRows(
    handleEditClick,
    handleDeleteClick,
    isDeleting,
    userId,
    users?.rows
  )
  return (
    <div className="UsersDashboard">
      <Button
        className="UsersDashboard__add"
        icon="add"
        onClick={() => setShowModal(true)}
        iconclassname="UsersDashboard__icon"
      >
        {t("Add new")}
      </Button>
      <HandleErrorState error={errorHandler.error}>
        <TableWrapper>
          <Table columns={columns} rows={rows} loading={loading} />
        </TableWrapper>
      </HandleErrorState>
      <TablePagination
        pages={users?.total_data}
        page={pageNumber}
        onPageChange={handlePageChange}
      />

      <UsersModal
        className="UsersDashboard__modal"
        isOpen={showModal}
        toggle={toggleModal}
        onAddUser={handleAddUser}
        onEditUser={handleEditUser}
        userId={userId}
        centered
      />
    </div>
  )
}
