import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "../Button/Button"
import { TableButton } from "../Table/TableButton/TableButton"

import "./FormButtons.scss"

interface Props {
  toggle?: () => void
  disabled: boolean
  isSubmitting: boolean
}

export const FormButtons = ({ toggle, disabled, isSubmitting }: Props) => {
  const { t } = useTranslation()
  return (
    <div className="FormButtons">
      <TableButton type="reset" className="FormButtons__close" onClick={toggle}>
        {t("Close")}
      </TableButton>
      <Button className="FormButtons__submit" type="submit" disabled={disabled}>
        {isSubmitting ? t("Saving...") : t("Save")}
      </Button>
    </div>
  )
}
