import React, { useCallback, useEffect, useState } from "react"
import { useCasesDashboardRows } from "../../components/CasesDashboard/utils/useCasesDashboardRows"
import { useDebouncedSearch } from "../../lib/hooks/useDebouncedSearch"
import { useErrorHandler } from "../../lib/hooks/useErrorHandler"
import { useTranslation } from "react-i18next"
import { LocaleSelector } from "../../components/shared/LocaleSelector/LocaleSelector"
import { AddNewButton } from "../../components/shared/AddNewButton/AddNewButton"
import { TableColumn } from "../../components/shared/Table/Table"
import { CasesTable } from "../../components/CasesTable/CasesTable"
import { CasesModal } from "../../components/CasesDashboard/CasesDashboardModal/CasesDashboardModal"
import { Input } from "../../components/shared/Input/Input"

import * as API from "../../api/Api"

import "./CasesDashboard.scss"

export const CasesDashboard = () => {
  const { t, i18n } = useTranslation()
  const [searchTitle, setSearchTitle] = useState<string>()
  const [pageNumber, setPageNumber] = useState(1)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [deleting, setDeleting] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [locale, setLocale] = useState<string>(i18n.language)
  const [caseId, setCaseId] = useState<number>()
  const [cases, setCases] = useState<API.CasesResponse>()

  useEffect(() => {
    setLocale(i18n.language)
  }, [i18n.language])

  const title = useDebouncedSearch(searchTitle || "", 500)
  const errorHandler = useErrorHandler()

  const getAllCases = useCallback(async () => {
    try {
      setLoading(true)
      const res = await API.getAllCases(pageNumber, { title }, locale)
      setCases(res)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, locale, title, i18n.language])

  useEffect(() => {
    getAllCases()
  }, [getAllCases])

  //change locale of Language
  const handleLocaleChange = (locale: string) => {
    setLocale(locale)
  }
  //change page on Pagination
  const handlePageChange = (currentPage: number) => {
    setPageNumber(currentPage)
  }

  //search
  const handleSearch = (e: any) => {
    setSearchTitle(e.target.value)
  }
  //open or close modal
  const toggleModal = () => {
    setShowModal(!showModal)
    setCaseId(undefined)
  }
  //handle edit click on Table
  const handleEditClick = (caseId: number) => {
    setCaseId(caseId)
  }

  //on Add or Edit case
  const reFetch = () => {
    getAllCases()
  }

  const handleDeleteClick = async (caseId: number) => {
    try {
      setCaseId(caseId)
      setDeleting(true)
      await API.deleteCase(caseId)
      setCases((prevState) => {
        if (!prevState) return
        return {
          total_data: prevState.total_data,
          rows: prevState.rows.filter((row) => row.id !== caseId)
        }
      })
      setShowModal(false)
    } catch (e) {
      errorHandler.handleError(e)
    } finally {
      setDeleting(false)
    }
  }

  //table properties
  const columns: TableColumn[] = [
    { key: "title", header: t("Title"), width: 350 },
    { key: "category", header: t("Category") },
    // { key: "country", header: t("Country"), width: 239 },
    { key: "date", header: t("Date"), width: 125 },
    { key: "edit", header: "" },
    { key: "delete", header: "" }
  ]

  const rows = useCasesDashboardRows(
    cases?.rows,
    toggleModal,
    handleEditClick,
    handleDeleteClick,
    deleting,
    caseId
  )

  return (
    <div className="CasesDashboard">
      <LocaleSelector language={locale} onLocaleChange={handleLocaleChange} />
      <div className="CasesDashboard__buttons">
        <Input
          className="CasesDashboard__search"
          type="text"
          name="title"
          id="title"
          placeholder={t("Search")}
          onChange={handleSearch}
          icon={loading ? "spinner" : "search"}
          submitting={loading}
        />
        <AddNewButton onClick={toggleModal} />
      </div>
      <CasesTable
        onPageChange={handlePageChange}
        totalData={cases?.total_data}
        error={errorHandler.error}
        toggleModal={toggleModal}
        currentPage={pageNumber}
        loading={loading}
        columns={columns}
        showModal={showModal}
        rows={rows}
      />
      <CasesModal
        caseId={caseId}
        isOpen={showModal}
        toggle={toggleModal}
        locale={locale}
        onEditOrAddCase={reFetch}
      />
    </div>
  )
}
