import React from "react"
import { useTranslation } from "react-i18next"
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext"
import { useRouter } from "../../lib/hooks/useRouter"
import { Button } from "../shared/Button/Button"
import { Dropdown } from "../shared/Dropdown/Dropdown"

import "./Menu.scss"

export const Menu = () => {
  const authCtx = useAuthContext()
  const router = useRouter()
  const { t, i18n: {language} } = useTranslation()
  const items = [
    { page: t("Cases"), path: `/${language}/admin/cases` },
    { page: t("General settings"), path: `/${language}/admin/settings` },
    { page: t("Users"), path: `/${language}/admin/users` },
    { page: t("Reports"), path: `/${language}/admin/reports` },
    { page: t("Labels"), path: `/${language}/admin/labels` },
    { page: t("Languages"), path: `/${language}/admin/languages` }
  ]
  return (
    <Dropdown
      className="Menu"
      direction="down"
      items={items}
      itemclick={(item) => router.history.push(item)}
      toggleClassName="Menu__toggle"
      toggleHeader={t("MENU")}
      itemClassName="Menu__item"
      menuClassName="Menu__body"
      button={
        <Button
          className="Menu__button"
          onClick={() => {
            authCtx.logout()
            router.history.push(`/${language}`)
          }}
        >
          {t("Logout")}
        </Button>
      }
    />
  )
}
