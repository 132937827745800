import { LabelsResponse } from "../../api/Labels"

export const formatTranslations = (labels?: LabelsResponse) => {
  if (!labels) return
  const translations = Object.entries(labels).map(([key, value]) => ({
    [key]: Object.assign(
      {},
      {
        translation: Object.assign(
          {},
          ...value.map((item) => ({ [item.key]: item.value }))
        )
      }
    )
  }))
  const resources = Object.assign({}, ...translations)
  return resources
}
