import React from "react"
import { formatBarChartData } from "../../lib/helpers/formatBarChartData"
import { useApiCall } from "../../lib/hooks/useApiCall"
import { BarChart } from "../shared/charts/BarChart/BarChart"
import { useTranslation } from "react-i18next"

import * as API from "../../api/Api"
import { getChartColors } from "../../lib/helpers/getChartColors"

interface Props {
  index: number
}

export const MeansOfAttacks = ({ index }: Props) => {
  const { i18n } = useTranslation()
  const {
    loading: meansLoading,
    data: meansOfAttacks,
    error: meansError
  } = useApiCall(API.getMeansOfAttacksWithCount, [i18n.language])

  const colors = getChartColors(161)

  const formattedMeansOfAttacks = formatBarChartData(index, meansOfAttacks)
  return (
    <BarChart
      id="meansOfAttacks"
      loading={meansLoading}
      data={formattedMeansOfAttacks}
      colors={colors}
      error={meansError}
    />
  )
}
