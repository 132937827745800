import React from "react"
import { Payload } from "../LineTooltip/LineTooltip"

import "./ActiveDot.scss"

interface Props {
  payload?: Payload[]
  onDotClick: (data: any) => void
  cx?: number
  cy?: number
  fill?: string
  dataKey?: string
}

export const ActiveDot = (props: Props) => {
  const { onDotClick, cx, cy, fill } = props

  return (
    <svg height="600" width="1500">
      <circle
        className="ActiveDot"
        cx={cx}
        cy={cy}
        r="6"
        fill={fill}
        onClick={() => onDotClick(props.dataKey)}
      />
    </svg>
  )
}
