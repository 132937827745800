import React from "react"
import { useTranslation } from "react-i18next"
import { useConfirmation } from "../../../../lib/context/ConfirmationContext/ConfirmationContext"
import { TableButton } from "../../../shared/Table/TableButton/TableButton"

import { UserRow } from "../../../../api/User"

export const useUsersRows = (
  onEditClick: (id: number) => void,
  onDeleteClick: (id: number) => void,
  isDeleting?: boolean,
  userId?: number,
  rows?: UserRow[]
) => {
  const { t } = useTranslation()
  const confirmation = useConfirmation()

  return rows?.map((row) => {
    return {
      email: row?.email,
      username: row?.username,
      edit: (
        <TableButton onClick={() => onEditClick(row.id)} icon="edit">
          {t("Edit")}
        </TableButton>
      ),
      delete: (
        <TableButton
          onClick={() =>
            confirmation.confirm(t("Delete")).then(() => onDeleteClick(row.id))
          }
          style={{ width: "85px" }}
        >
          {isDeleting && row.id === userId ? t("deleting") : t("Delete")}
        </TableButton>
      )
    }
  })
}
