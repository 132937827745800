import React from "react"

//icons
import birdlogo from "../../assets/icons-png/bird-logo.png"
import { useTranslation } from "react-i18next"
import foundationlogo from "../../assets/icons-png/share-foundation-logo.png"
import birnlogo from "../../assets/icons-png/birn.png"
//styles
import "./Footer.scss"

export const Footer = () => {
  const { t } = useTranslation()
  const url = process.env.REACT_APP_API_URL
  const nations = [
    { name: t("Albania"), to: "https://al.bird.tools/" },
    { name: t("Bosnia and Herzegovina"), to: "https://bih.bird.tools/" },
    { name: t("Croatia"), to: "https://hr.bird.tools/" },
    { name: t("Hungary"), to: "https://hu.bird.tools/" },
    { name: t("Kosovo"), to: "https://xk.bird.tools/" },
    { name: t("Montenegro"), to: "https://me.bird.tools/" },
    { name: t("North Macedonia"), to: "https://mk.bird.tools/" },
    { name: t("Romania"), to: "https://ro.bird.tools/" },
    { name: t("Serbia"), to: "https://rs.bird.tools/" },
    { name: t("All countries"), to: "https://monitoring.bird.tools/" }
  ]

  return (
    <div className="Footer">
      <div className="Footer__nations">
        {nations.map((nation, i) => {
          if (url?.includes("xk") && nation.name === "Kosovo") return null
          if (url?.includes("me") && nation.name === "Montenegro") return null
          if (url?.includes("al") && nation.name === "Albania") return null
          return (
            <a
              className="Footer__navigation"
              key={`Nation-${i}`}
              target="_blank"
              href={nation.to}
              rel="noreferrer noopener"
            >
              {" "}
              {nation.name}
            </a>
          )
        })}
      </div>
      <div className="Footer__content">
        <div className="Footer__content_left">
          <img className="Footer__bird_logo" alt="bird_logo" src={birdlogo} />
          <p className="Footer__description">{t("footer description")}</p>
        </div>
        <div className="Footer__content_right">
          <p className="Footer__sponsor">{t("powered by")}</p>
          <div className="Footer__logos">
            <img
              alt="foundation_logo"
              className="Footer__foundation_logo"
              src={foundationlogo}
            />
            <img alt="birn_logo" className="Footer__birn_logo" src={birnlogo} />
          </div>
        </div>
      </div>
    </div>
  )
}
