import React from "react"
import { useApiCall } from "../../../lib/hooks/useApiCall"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { TableButton } from "../Table/TableButton/TableButton"
import * as API from "../../../api/Api"

import cs from "classnames"
import "./LocaleSelector.scss"
import { useHistory } from "react-router-dom"
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext"
interface Props {
  onLocaleChange: (locale: string) => void
  language: string
}
export const LocaleSelector = ({ language, onLocaleChange }: Props) => {
  const { loading, data: locale, error } = useApiCall(API.getLanguages)

  const history = useHistory()
  const {
    location: { pathname }
  } = history
  const { changeLanguage } = useLanguageContext()

  const handleClick = (locale: string) => {
    const urlPathname = pathname.split("/")
    urlPathname.splice(0, 2)
    const urlAfterLanguage = urlPathname.join("/")
    // setSelectedItem(item.locale)
    changeLanguage(locale)
    history.push(`/${locale}/${urlAfterLanguage}`)
  }

  if (loading) return <LocaleSelectorPlaceholder />

  if (error) return null
  return (
    <div className="LocaleSelector">
      <div className="LocaleSelector__container">
        {locale?.rows.map((row) => (
          <TableButton
            className={cs("LocaleSelector__button", {
              LocaleSelector__button_active: language === row.locale
            })}
            key={row.id}
            onClick={() => handleClick(row.locale)}
          >
            {row.language}
          </TableButton>
        ))}
      </div>
    </div>
  )
}

function LocaleSelectorPlaceholder() {
  return (
    <div className="LocaleSelector__placeholder">
      <SkeletonTheme color="#675656" highlightColor="#1C1616">
        <Skeleton width="130px" height="40px" style={{ marginRight: "5px" }} />
        <Skeleton width="130px" height="40px" style={{ marginRight: "5px" }} />
        <Skeleton width="130px" height="40px" style={{ marginRight: "5px" }} />
      </SkeletonTheme>
    </div>
  )
}
